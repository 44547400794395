import { InitiatorFragment } from './initiator';
import { ChannelFragment } from './channel';

/**
 * Portion of the {@link InventoryEntryFragment}
 */

export const DefaultInventoryEntryFragment = `
  fragment InventoryEntryFragment on InventoryEntry {
    id
    sku
    version
    createdAt
    lastModifiedAt
    quantityOnStock
    availableQuantity
    restockableInDays
    expectedDelivery
    supplyChannel {
      ...ChannelFragment
    }
    createdBy {
      ...InitiatorFragment
    }
    lastModifiedBy {
      ...InitiatorFragment
    }
  }
`;

/**
 * Reusable GraphQL fragment. It concatenates
 * {@link InitiatorFragment} and {@link DefaultInventoryEntryFragment}
 */
export const InventoryEntryFragment = `
  ${InitiatorFragment}

  ${ChannelFragment}

   ${DefaultInventoryEntryFragment}
`;
