import { CustomerFragment } from '../../fragments';

/**
 * Portion of the GraphQL query used in the `updateBillingAddress` API endpoint
 */
export const updateBillingAddressMutation = `
  mutation updateBillingAddress($version: Long!, $actions: [MyCustomerUpdateAction!]!, $storeKey: KeyReferenceInput) {
    user: updateMyCustomer(version: $version, actions: $actions, storeKey: $storeKey) {
      ...DefaultCustomer
    }
  }
`;

/**
 * Default GraphQL query for the `updateBillingAddress` API endpoint. It concatenates
 * the {@link CustomerFragment} and {@link updateBillingAddressMutation}
 */
export const updateBillingAddressDefaultQuery = `
  ${CustomerFragment}

  ${updateBillingAddressMutation}
`;
