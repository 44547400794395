import { CustomerFragment } from '../../fragments';

/**
 * Portion of the GraphQL query used in the `customerUpdateMe` API endpoint
 */
export const customerUpdateMeMutation = `
  mutation customerUpdateMe($version: Long!, $actions: [MyCustomerUpdateAction!]!, $storeKey: KeyReferenceInput) {
    user: updateMyCustomer(version: $version, actions: $actions, storeKey: $storeKey) {
      ...DefaultCustomer
    }
  }
`;

/**
 * Default GraphQL query for the `customerUpdateMe` API endpoint. It concatenates
 * the {@link CustomerFragment} and {@link customerUpdateMeMutation}
 */
export const customerUpdateMeDefaultQuery = `
  ${CustomerFragment}

  ${customerUpdateMeMutation}
`;
