import generateContext from '../helpers/context';
import useReviewMethods from './useReviewMethods';
import { computed } from '@nuxtjs/composition-api';
import { sharedRef, Logger } from '@vue-storefront/core';

import type { Ref } from '@nuxtjs/composition-api';
import type { Context, UseReviewErrors, UseReview } from '@vue-storefront/core';
import type { AddReviewParams, GetReviewParams } from '@vsf-enterprise/commercetools-api';
import type { UseReviewData } from '../types';

/**
 * Composable for loading and adding products reviews
 */
function useReview(id: string): UseReview<UseReviewData, AddReviewParams, GetReviewParams> {
  const context: Context = generateContext(useReviewMethods);
  const reviews: Ref<UseReviewData> = sharedRef([], `useReviews-reviews-${id}`);
  const loading: Ref<boolean> = sharedRef(false, `useReviews-loading-${id}`);
  const error: Ref<UseReviewErrors> = sharedRef({
    search: null,
    addReview: null
  }, `useReviews-error-${id}`);

  /**
   * Searches reviews with given params
   */
  async function search(searchParams) {
    Logger.debug(`useReview/${id}/search`, searchParams);

    try {
      loading.value = true;
      reviews.value = await useReviewMethods.searchReviews(context, searchParams);
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error(`useReview/${id}/search`, err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Adds a review
   */
  async function addReview(params) {
    Logger.debug(`useReview/${id}/addReview`, params);

    try {
      loading.value = true;
      reviews.value = await useReviewMethods.addReview(context, params);
      error.value.addReview = null;
    } catch (err) {
      error.value.addReview = err;
      Logger.error(`useReview/${id}/addReview`, err);
    } finally {
      loading.value = false;
    }
  }

  return {
    search,
    addReview,
    reviews: computed(() => reviews.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value)
  };
}

export {
  useReview
};
