import { CustomerFragment } from '../../fragments';

/**
 * Portion of the GraphQL query used in the `setDefaultShippingAddress` API endpoint
 */
export const setDefaultShippingAddressMutation = `
  mutation setDefaultShippingAddress($version: Long!, $actions: [MyCustomerUpdateAction!]!, $storeKey: KeyReferenceInput) {
    user: updateMyCustomer(version: $version, actions: $actions, storeKey: $storeKey) {
      ...DefaultCustomer
    }
  }
`;

/**
 * Default GraphQL query for the `setDefaultShippingAddress` API endpoint. It concatenates
 * the {@link CustomerFragment} and {@link setDefaultShippingAddressMutation}
 */
export const setDefaultShippingAddressDefaultQuery = `
  ${CustomerFragment}

  ${setDefaultShippingAddressMutation}
`;
