import gql from 'graphql-tag';
import { addBillingAddressDefaultQuery } from './defaultQuery';
import { setDefaultBillingAddressAction } from '../setDefaultBillingAddress';

import type { CustomQuery } from '@vue-storefront/core';
import type { AddressInput, Customer } from '@vsf-enterprise/commercetools-types';
import type { Context } from '../../types/setup';

const addAddressAction = (address: AddressInput) => ({ addAddress: { address } });
const setAsBillingAddressAction = (addressId: string) => ({ addBillingAddressId: { addressId } });

/**
 * Parameters for the `addBillingAddress` API endpoint
 */
export interface AddBillingAddressParams {

  /**
   * New billing address
   */
  address: AddressInput & {
    isDefault?: boolean;
  };

  /**
   * Current user
   */
  user: {
    version: string;
  };
}

/**
 * Data returned from the `addBillingAddress` API endpoint
 */
export type AddBillingAddressResponse = Partial<Customer>;

/**
 * Endpoint for adding new billing address for the current user. By default, it uses
 * the {@link addBillingAddressDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Address information and user version
 * @param customQuery - Custom queries included in the request
 * @returns User information
 */
export async function addBillingAddress(
  context: Context,
  params: AddBillingAddressParams,
  customQuery?: CustomQuery
): Promise<AddBillingAddressResponse> {
  const { isDefault, ...address } = params.address;
  const defaultVariables = {
    version: params.user.version,
    actions: [
      addAddressAction(address)
    ]
  };

  const { addBillingAddress } = context.extendQuery(
    customQuery,
    {
      addBillingAddress: {
        query: addBillingAddressDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  const addAddressRequest = await context.client.mutate({
    mutation: gql`${addBillingAddress.query}`,
    variables: addBillingAddress.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });

  const newAddress = addAddressRequest.data.user.addresses[addAddressRequest.data.user.addresses.length - 1];

  const actions: any[] = [
    setAsBillingAddressAction(newAddress.id)
  ];

  if (isDefault) {
    actions.push(setDefaultBillingAddressAction(newAddress.id));
  }

  const response = await context.client.mutate({
    mutation: gql`${addBillingAddress.query}`,
    variables: {
      version: addAddressRequest.data.user.version,
      actions
    },
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });

  // TODO: We should consider returning whole `response` object
  return response.data.user;
}
