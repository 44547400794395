import gql from 'graphql-tag';
import { createCartDefaultQuery } from './defaultQuery';
import { getStoreKey } from '../../helpers/utils';

import type { CustomQuery } from '@vue-storefront/core';
import type { Cart, CartDraft } from '@vsf-enterprise/commercetools-types';
import type { Context, QueryResponse } from '../../types/setup';

/**
 * Parameters for the `createCart` API endpoint
 */
export interface CreateCartParams extends Omit<CartDraft, 'currency'> {

  /**
   * Cart currency
   */
  currency?: string;
}

/**
 * Data returned from the `createCart` API endpoint
 */
export type CreateCartResponse = QueryResponse<'cart', Cart>;

/**
 * Endpoint for creating a new cart for the current user. By default, it uses
 * the {@link createCartDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param cartDraft - Cart information
 * @param customQuery - Custom queries included in the request
 * @returns User cart information
 */
export async function createCart(
  context: Context,
  cartDraft: CreateCartParams = {},
  customQuery?: CustomQuery
): Promise<CreateCartResponse> {
  const { locale, acceptLanguage, currency, country, store, inventoryMode } = context.config;

  const defaultVariables = {
    acceptLanguage,
    locale,
    currency,
    draft: {
      currency,
      country,
      inventoryMode,
      ...cartDraft
    },
    ...getStoreKey(store)
  };

  const { createCart: createCartGql } = context.extendQuery(
    customQuery,
    {
      createCart: {
        query: createCartDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  return await context.client.mutate({
    mutation: gql`${createCartGql.query}`,
    variables: createCartGql.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });
}
