import { CartFragment } from './../../fragments';

/**
 * Portion of the GraphQL query used in the `getCart` API endpoint
 */
export const getCartQuery = `
  query getCart($cartId: String!, $locale: Locale!, $acceptLanguage: [Locale!], $currency: Currency!) {
    cart(id: $cartId) {
      ...DefaultCart
    }
  }
`;

/**
 * Default GraphQL query for the `getCart` API endpoint. It concatenates
 * the {@link CartFragment} and {@link getCartQuery}
 */
export const getCartDefaultQuery = `
  ${CartFragment}
  ${getCartQuery}
`;
