import gql from 'graphql-tag';
import { addMultipleToMyShoppingListDefaultQuery } from './defaultQuery';
import { addLineItemAction } from '../../helpers/actions/wishlist';

import type { CustomQuery } from '@vue-storefront/core';
import type { ShoppingList } from '@vsf-enterprise/commercetools-types';
import type { ProductIdentifier } from '../../types/Api';
import type { Context, QueryResponse } from '../../types/setup';

/**
 * Parameters for the `addMultipleToMyShoppingList` API endpoint
 */
export interface AddMultipleToMyShoppingListParams {

  /**
   * Array of product SKUs
   */
  products: ProductIdentifier[];

  /**
   * Wishlist ID
   */
  id: string;

  /**
   * Wishlist version
   */
  version: string;
}

/**
 * Data returned from the `addMultipleToMyShoppingList` API endpoint
 */
export type AddMultipleToMyShoppingListResponse = QueryResponse<'wishlist', ShoppingList>;

/**
 * Endpoint for adding multiple items to the wishlist. By default, it uses
 * the {@link addMultipleToMyShoppingListDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Product SKUs and wishlist information
 * @param customQuery - Custom queries included in the request
 * @returns Whole user wishlist with all items
 */
export async function addMultipleToMyShoppingList(
  context: Context,
  params: AddMultipleToMyShoppingListParams,
  customQuery?: CustomQuery
): Promise<AddMultipleToMyShoppingListResponse> {
  const { products, id, version } = params;
  const { acceptLanguage, currency, country } = context.config;
  const defaultVariables = {
    acceptLanguage,
    currency,
    country,
    id,
    version,
    actions: products.map(product => addLineItemAction(product))
  };

  const { updateShoppingList } = context.extendQuery(
    customQuery,
    {
      updateShoppingList: {
        query: addMultipleToMyShoppingListDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  return await context.client.mutate({
    mutation: gql`${updateShoppingList.query}`,
    variables: updateShoppingList.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });
}
