/**
 * Default GraphQL query for the `getChannel` API endpoint
 */
export const getChannelDefaultQuery = `
  query channels(
    $where: String,
    $limit: Int,
    $offset: Int
    $acceptLanguage: [Locale!]
  ) {
    channels(
      where: $where,
      limit: $limit,
      offset: $offset,
    ) {
      offset
      count
      total
      results {
        id,
        version,
        key,
        roles,
        name(acceptLanguage: $acceptLanguage),
        description(acceptLanguage: $acceptLanguage),
        address {
          additionalAddressInfo
          additionalStreetInfo
          apartment
          building
          city
          company
          country
          department
          email
          fax
          firstName
          id
          key
          lastName
          mobile
          pOBox
          phone
          postalCode
          region
          salutation
          state
          streetName
          streetNumber
          title
        },
        geoLocation {
          ... on Point {
            type
            coordinates
          }
        },
        createdAt,
        lastModifiedAt,
        custom {
          customFieldsRaw {
            name
            value
          }
        }
      }
    }
  }
`;
