import { OrderFragment } from '../../fragments';

/**
 * Portion of the GraphQL query used in the `getOrders` API endpoint
 */
export const getMyOrdersQuery = `
  query getMyOrders($where: String, $sort: [String!], $limit: Int, $offset: Int, $locale: Locale!, $acceptLanguage: [Locale!], $currency: Currency!) {
    me {
      orders(where: $where, sort: $sort, limit: $limit, offset: $offset) {
        results {
          ...DefaultOrder
        }
        total
        offset
        count
      }
    }
  }
`;

/**
 * Default GraphQL query for the `getOrders` API endpoint. It concatenates
 * the {@link OrderFragment} and {@link getMyOrdersQuery}
 */
export const getOrdersDefaultQuery = `
  ${OrderFragment}

  ${getMyOrdersQuery}
`;
