import gql from 'graphql-tag';
import { addToMyShoppingListDefaultQuery } from './defaultQuery';
import { addLineItemAction } from '../../helpers/actions/wishlist';

import type { CustomQuery } from '@vue-storefront/core';
import type { ProductIdentifier } from '../../types/Api';
import type { Context } from '../../types/setup';
import type { CreateMyShoppingListResponse } from '../createMyShoppingList';

/**
 * Parameters for the `addToMyShoppingList` API endpoint
 */
export interface AddToMyShoppingListParams {

  /**
   * Product SKU
   */
  product: ProductIdentifier;

  /**
   * Wishlist ID
   */
  id: string;

  /**
   * Wishlist version
   */
  version: string;
}

/**
 * Data returned from the `addToMyShoppingList` API endpoint
 */
export type AddToMyShoppingListResponse = CreateMyShoppingListResponse;

/**
 * Endpoint for adding item to the wishlist. By default, it uses
 * the {@link addToMyShoppingListDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Product SKU and wishist information
 * @param customQuery - Custom queries included in the request
 * @returns User wishlist
 */
export async function addToMyShoppingList(
  context: Context,
  params: AddToMyShoppingListParams,
  customQuery?: CustomQuery
): Promise<AddToMyShoppingListResponse> {
  const { product, id, version } = params;
  const { acceptLanguage, currency, country } = context.config;
  const defaultVariables = {
    acceptLanguage,
    currency,
    country,
    id,
    version,
    actions: [
      addLineItemAction(product)
    ]
  };

  const { updateShoppingList } = context.extendQuery(
    customQuery,
    {
      updateShoppingList: {
        query: addToMyShoppingListDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  return await context.client.mutate({
    mutation: gql`${updateShoppingList.query}`,
    variables: updateShoppingList.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });
}
