import type { Token } from '../types/setup';

/**
 * Helper for checking if given token is an anonymous session
 *
 * @param token - commercetools access token
 *
 * @returns Boolean indicating if given token is an anonymous session
 */
export function isAnonymousSession(token?: Token): boolean {
  return Boolean(token?.scope?.includes('anonymous_id'));
}

/**
 * Helper for checking if given token represents an user session
 *
 * @param token - commercetools access token
 *
 * @returns Boolean indicating if given token is an user session
 */
export function isUserSession(token?: Token): boolean {
  return Boolean(token?.scope?.includes('customer_id'));
}

export function getStoreKey(store: string | undefined) {
  return store
    ? ({ storeKey: store?.split('/')[0] })
    : {};
}
