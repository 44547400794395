import gql from 'graphql-tag';
import { getStoresDefaultQuery } from './defaultQuery';

import type { Context, CustomQuery } from '@vue-storefront/core';
import type { StoreQueryResult } from '@vsf-enterprise/commercetools-types';

/**
 * Parameters for the `getStores` API endpoint
 */
export interface GetStoresParams {
  // TODO: Was this added here by mistake? In all other endpoints, `customQuery` is a third parameter, not part of `params` object
  /**
   * Custom Query
   */
  customQuery: CustomQuery;
}

/**
 * Data returned from the `getStores` API endpoint
 */
export type GetStoresResponse = StoreQueryResult;

/**
 * Endpoint for fetching available stores. By default, it uses
 * the {@link getStoresDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Custom queries included in the request
 * @returns Stores data
 */
export async function getStores(
  context: Context,
  params?: GetStoresParams
): Promise<GetStoresResponse> {
  const variables = { locale: context.config.locale };
  const { customQuery } = Object(params);

  const { getStoresData } = context.extendQuery(
    customQuery,
    {
      getStoresData: {
        query: getStoresDefaultQuery,
        variables
      }
    }
  );

  const response = await context.client.query({
    query: gql`${getStoresData.query}`,
    variables: getStoresData.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });

  // TODO: We should consider returning whole `response` object
  return response.data.stores;
}
