import { LocalizedStringFragment } from './localized-string';
import { ChannelFragment } from './channel';
import { InitiatorFragment } from './initiator';

/**
 * Portion of the {@link StoreFragment}
 */
export const DefaultStoreFragment = `
  fragment StoreFragment on Store {
    id
    version
    key
    languages
    createdAt
    lastModifiedAt
    name (locale: $locale)
    nameAllLocales {
      ...LocalizedStringFragment
    }
    distributionChannels {
      ...ChannelFragment
    }
    supplyChannels {
      ...ChannelFragment
    }
    createdBy {
      ...InitiatorFragment
    }
    lastModifiedBy {
      ...InitiatorFragment
    }
  }
`;

/**
 * Reusable GraphQL fragment. It concatenates
 * {@link LocalizedStringFragment}, {@link ChannelFragment},
 * {@link InitiatorFragment} and {@link DefaultStoreFragment}
 */
export const StoreFragment = `
  ${LocalizedStringFragment}

  ${ChannelFragment}

  ${InitiatorFragment}

  ${DefaultStoreFragment}
`;
