import { InventoryEntriesQueryResultFragment } from './../../fragments';

/**
 * Portion of the GraphQL query used in the `getInventory` API endpoint
 */
export const inventoryEntriesQuery = `
  query inventoryEntries(
    $where: String
    $sort: [String!]
    $limit: Int
    $offset: Int
    $locale: Locale!
  ) {
    inventoryEntries(
      where: $where
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      ...InventoryEntriesQueryResultFragment
    }
  }
`;

/**
 * Default GraphQL query for the `getInventory` API endpoint
 */
export const getInventoryDefaultQuery = `
  ${InventoryEntriesQueryResultFragment}

  ${inventoryEntriesQuery}
`;
