import { ReferenceFragment } from './reference';

/**
 * Portion of the {@link InitiatorFragment}
 */
export const DefaultInitiatorFragment = `
  fragment InitiatorFragment on Initiator {
    isPlatformClient
    externalUserId
    anonymousId
    clientId
    customerRef {
      ...ReferenceFragment
    }
    userRef {
      ...ReferenceFragment
    }
  }
`;

/**
 * Reusable GraphQL fragment. It concatenates
 * {@link ReferenceFragment} and {@link DefaultInitiatorFragment}
 */
export const InitiatorFragment = `
  ${ReferenceFragment}

  ${DefaultInitiatorFragment}
`;
