/**
 * Reusable GraphQL fragment
 */
export const ShippingMethodFragment = `
  fragment DefaultShippingMethod on ShippingMethod {
    id
    version
    name
    isDefault
    localizedDescription(acceptLanguage: $acceptLanguage)
    key
    zoneRates {
      zone {
        id
        name
      }
      shippingRates {
        freeAbove {
          type
          centAmount
          currencyCode
        }
        isMatching
        price {
          centAmount
          currencyCode
        }
      }
    }
  }
`;
