import gql from 'graphql-tag';
import { getProductDefaultQuery } from './defaultQuery';
import { buildProductWhere } from '../../helpers/search';

import type { CustomQuery } from '@vue-storefront/core';
import type { ProductQueryResult } from '@vsf-enterprise/commercetools-types';
import type { ProductWhereSearch } from '../../types/Api';
import type { Context, QueryResponse } from '../../types/setup';

/**
 * Data returned from the `getProduct` API endpoint
 */
export type GetProductResponse = QueryResponse<'products', ProductQueryResult>;

/**
 * Endpoint for fetching filtered, and paginated products. By default, it uses
 * the {@link getProductDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Filtering and pagination options
 * @param customQuery - Custom queries included in the request
 * @returns Product data
 */
export async function getProduct(
  context: Context,
  params: ProductWhereSearch,
  customQuery?: CustomQuery
): Promise<GetProductResponse> {
  const { locale, acceptLanguage, currency, country, channel } = context.config;

  const defaultVariables = {
    where: buildProductWhere(context.config, params),
    skus: params.skus,
    limit: params.limit,
    offset: params.offset,
    locale,
    acceptLanguage,
    currency,
    country,
    ...(channel && { channelId: channel })
  };

  const { products } = context.extendQuery(
    customQuery,
    {
      products: {
        query: getProductDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  try {
    const request = await context.client.query({
      query: gql`${products.query}`,
      variables: products.variables,
      // temporary, seems like bug in apollo:
      // @link: https://github.com/apollographql/apollo-client/issues/3234
      fetchPolicy: 'no-cache',
      context: {
        req: context.req,
        res: context.res
      }
    });
    return request;
  } catch (error) {
    throw error.graphQLErrors?.[0] || error.networkError?.result || error;
  }
}
