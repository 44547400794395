import type {
  Context,
  UseStoreFactoryLoadParamArguments
} from '@vue-storefront/core';
import { StoresData } from '../types';

type UseStoreMethods<STORES> = {
  load: (context: Context, params: UseStoreFactoryLoadParamArguments) => Promise<STORES>;
}

const useStoreMethods: UseStoreMethods<StoresData> = {
  load: async (context: Context, params: UseStoreFactoryLoadParamArguments): Promise<StoresData> => {
    const { api, config } = context.$ct;
    const { customQuery } = params;

    return {
      ...await api.getStores({ customQuery }),
      _selectedStore: config.store
    };
  }
};

export default useStoreMethods;
