import { CartFragment, CustomerFragment } from './../../fragments';

/**
 * Portion of the GraphQL query used in the `getMe` API endpoint when `customer` parameter is not passed
 */
export const getBasicProfileQuery = `
  query getBasicProfile($locale: Locale!, $acceptLanguage: [Locale!], $currency: Currency!) {
    me {
      activeCart {
        ...DefaultCart
      }
    }
  }
`;

/**
 * Default GraphQL query for the `getMe` API endpoint when `customer` parameter is not passed.
 * It concatenates the {@link CartFragment} and {@link getBasicProfileQuery}
 */
export const getMeBasicProfileDefaultQuery = `
  ${CartFragment}

  ${getBasicProfileQuery}
`;

/**
 * Portion of the GraphQL query used in the `getMe` API endpoint when `customer` parameter is passed
 */
export const getFullProfileQuery = `
  query getFullProfile($locale: Locale!, $acceptLanguage: [Locale!], $currency: Currency!) {
    me {
      activeCart {
        ...DefaultCart
      }
      customer {
        ...DefaultCustomer
      }
    }
  }
`;

/**
 * Default GraphQL query for the `getMe` API endpoint when `customer` parameter is passed.
 * It concatenates the {@link CartFragment}, {@link CustomerFragment} and {@link getFullProfileQuery}
 */
export const getMeFullProfileDefaultQuery = `
  ${CartFragment}

  ${CustomerFragment}

  ${getFullProfileQuery}
`;

