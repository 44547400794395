import { isUserSession } from '../../helpers/utils';

import type { Context } from '../../types/setup';
import { CT_COOKIE_NAME } from '../../types/setup';
import TokenParser from '../../token/tokenParser';

/**
 * Endpoint for checking if user is logged in or not. It doesn't use any GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @returns Boolean indicating if user is logged in or not
 */
export async function isLoggedIn(
  context: Context
): Promise<boolean> {
  const { config } = context;

  if (typeof config.handleIsLoggedIn === 'function') {
    return await config.handleIsLoggedIn(context);
  }

  // @ts-ignore
  const token = TokenParser.parseToken(context.req.cookies[CT_COOKIE_NAME]);
  return isUserSession(token);
}
