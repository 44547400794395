import { Context, CustomQuery } from '@vue-storefront/core';
import { catchApiErrors } from '../helpers/internals/handleApiErrors';
import { ErrorResponse } from '../types';

import type { UseReviewData } from '../types';
import type { AddReviewParams, GetReviewParams } from '@vsf-enterprise/commercetools-api';

type UseReviewMethods<REVIEW, REVIEWS_SEARCH_PARAMS, REVIEW_ADD_PARAMS> = {
  searchReviews: (context: Context, params: REVIEWS_SEARCH_PARAMS & { customQuery?: CustomQuery }) => Promise<REVIEW>;
  addReview: (context: Context, params: REVIEW_ADD_PARAMS & { customQuery?: CustomQuery }) => Promise<REVIEW>;
};

const useReviewMethods: UseReviewMethods<UseReviewData, GetReviewParams, AddReviewParams> = {
  searchReviews: async (context: Context, params) => {
    const reviews = await context.$ct.api.getReview(params, params.customQuery);
    catchApiErrors(reviews as unknown as ErrorResponse);
    const product: any = await context.$ct.api.getProduct({
      id: params.productId,
      limit: 1
    });
    catchApiErrors(product as unknown as ErrorResponse);

    return {
      ...reviews,
      ...product.data.products.results[0].reviewRatingStatistics
    };
  },
  addReview: async (context: Context, params) => {
    const response = await context.$ct.api.addReview(params, params.customQuery);
    catchApiErrors(response as unknown as ErrorResponse);

    return useReviewMethods.searchReviews(context, {
      productId: params.productId,
      limit: params.limit,
      offset: params.offset,
      sort: params.sort,
      where: params.where
    });
  }
};

export default useReviewMethods;
