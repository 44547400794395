/**
 * Default GraphQL query for the `getMyShoppingList` API endpoint
 */
export const getMyShoppingListDefaultQuery = `
  query getMe($acceptLanguage: [Locale!], $currency: Currency!, $country: Country!) {
    me {
      shoppingLists(limit: 1, offset: 0) {
        results {
          id
          version
          lineItems {
            id
            quantity
            name(acceptLanguage: $acceptLanguage)
            productSlug(acceptLanguage: $acceptLanguage)
            variant {
              sku
              price(currency: $currency, country: $country) {
                tiers {
                  value {
                    centAmount
                  }
                }
                value {
                  centAmount
                  currencyCode
                }
                discounted {
                  value {
                    centAmount
                    currencyCode
                  }
                  discount {
                    isActive
                    name(acceptLanguage: $acceptLanguage)
                  }
                }
              }
              images {
                url
                label
              }
            }
          }
        }
      }
    }
  }
`;
