import { AddressFragment } from './address';

/**
 * Portion of the {@link CustomerFragment}
 */
export const DefaultCustomerFragment = `
  fragment DefaultCustomer on Customer {
    version
    firstName
    lastName
    email
    dateOfBirth
    addresses {
      id
    }
    shippingAddresses {
      ...DefaultAddress
    }
    billingAddresses {
      ...DefaultAddress
    }
    defaultBillingAddressId
    defaultShippingAddressId
  }
`;

/**
 * Reusable GraphQL fragment. It concatenates
 * {@link AddressFragment} and {@link DefaultCustomerFragment}
 */

export const CustomerFragment = `
  ${AddressFragment}

  ${DefaultCustomerFragment}
`;
