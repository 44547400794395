import { CustomerFragment, CartFragment } from '../../fragments';

/**
 * Portion of the GraphQL query used in the `customerSignMeIn` API endpoint
 */
export const customerSignMeInMutation = `
  mutation customerSignMeIn($draft: CustomerSignMeInDraft!, $locale: Locale!, $acceptLanguage: [Locale!], $currency: Currency!, $storeKey: KeyReferenceInput) {
    user: customerSignMeIn(draft: $draft, storeKey: $storeKey) {
      customer {
        ...DefaultCustomer
      }
      cart {
        ...DefaultCart
      }
    }
  }
`;

/**
 * Default GraphQL query for the `customerSignMeIn` API endpoint. It concatenates
 * the {@link CustomerFragment}, {@link CartFragment} and {@link customerSignMeInMutation}
 */
export const customerSignMeInDefaultQuery = `
  ${CustomerFragment}

  ${CartFragment}

  ${customerSignMeInMutation}
`;
