import { CartFragment } from './../../fragments';

/**
 * Portion of the GraphQL query used in the `createCart` API endpoint
 */
export const createCartMutation = `
  mutation createCart($draft: MyCartDraft!, $locale: Locale!, $acceptLanguage: [Locale!], $currency: Currency!, $storeKey: KeyReferenceInput) {
    cart: createMyCart(draft: $draft, storeKey: $storeKey) {
      ...DefaultCart
    }
  }
`;

/**
 * Default GraphQL query for the `createCart` API endpoint. It concatenates
 * the {@link CartFragment} and {@link createCartMutation}
 */
export const createCartDefaultQuery = `
  ${CartFragment}

  ${createCartMutation}
`;
