/**
 * Default GraphQL query for the `getReview` API endpoint
 */
export const getReviewDefaultQuery = `
  query reviews(
    $where: String!,
    $limit: Int,
    $offset: Int,
    $sort: [String!]
  ) {
    reviews(
      where: $where,
      limit: $limit,
      offset: $offset,
      sort: $sort
    ) {
      offset,
      count,
      total,
      results {
        id,
        text,
        authorName,
        rating,
        createdAt
      }
    }
  }
`;
