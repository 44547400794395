import gql from 'graphql-tag';
import { getFacetProductProjectionDefaultQuery } from './defaultQuery';
import { getFiltersPerStrategy, getFacets, getSorts } from '../../helpers/facet';

import type { CustomQuery } from '@vue-storefront/core';
import type { ProductProjectionSearchResult } from '@vsf-enterprise/commercetools-types';
import type { Context, QueryResponse } from '../../types/setup';

/**
 * Parameters for the `getFacetProductProjection` API endpoint
 */
export interface GetFacetProductProjectionParams {
  page?: number;
  perPage?: number;
  filter?: Record<string, any>;
  phrase?: string
  sort?: string;
  currency?: string;
  country?: string;
  locale?: string;
  facetParams: Record<string, any>
  includeChannelIds?: Array<string>,
  channelId?: string,
  customParams?: Record<string, any>;
}

/**
 * Data returned from the `getFacetProductProjection` API endpoint
 */
export type GetFacetProductProjectionResponse = QueryResponse<'productProjectionSearch', ProductProjectionSearchResult>;

/**
 * Endpoint for loading products based on provided filters, as well as sorting and pagination options.
 * By default, it uses the {@link getFacetProductProjectionDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Filters, sorting and pagination options
 * @param customQuery - Custom queries included in the request
 * @returns Paginated products data
 */
export async function getFacetProductProjection(
  context: Context,
  params: GetFacetProductProjectionParams,
  customQuery?: CustomQuery
): Promise<GetFacetProductProjectionResponse> {
  const { acceptLanguage, currency, country, locale } = context.config;
  const {
    filter,
    sort,
    page,
    perPage,
    phrase,
    facetParams,
    customParams = {},
    includeChannelIds,
    channelId
  } = params;

  const facets = getFacets(facetParams);
  const sorts = getSorts(facetParams, sort);
  const filtersPerStrategy = getFiltersPerStrategy(facetParams, filter);

  const defaultVariables = params ? {
    ...filtersPerStrategy,
    currency,
    country,
    limit: perPage,
    sorts,
    offset: (page - 1) * perPage,
    locale,
    acceptLanguage,
    facets,
    text: phrase || '',
    includeChannelIds,
    channelId,
    ...customParams
  } : { locale };

  const { productProjections } = context.extendQuery(
    customQuery,
    {
      productProjections: {
        query: getFacetProductProjectionDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  return await context.client.query({
    query: gql`${productProjections.query}`,
    variables: productProjections.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });
}
