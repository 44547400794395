import gql from 'graphql-tag';
import { getCartDefaultQuery } from './defaultQuery';

import type { Cart } from '@vsf-enterprise/commercetools-types';
import type { Context, QueryResponse } from '../../types/setup';
import type { CustomQuery } from '@vue-storefront/core';

/**
 * Data returned from the `getCart` API endpoint
 */
export type GetCartResponse = QueryResponse<'cart', Cart>;

/**
 * @deprecated Starting from version 1.7.2 use the `getMe` endpoint
 *
 * Endpoint for loading cart of the currently logged user. By default, it uses
 * the {@link getCartDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param cartId - Cart ID
 * @param customQuery - Custom Query
 * @returns Cart data
 */
export async function getCart(
  context: Context,
  cartId: string,
  customQuery?: CustomQuery
): Promise<GetCartResponse> {
  const { locale, acceptLanguage, currency } = context.config;

  const defaultVariables = {
    cartId,
    locale,
    acceptLanguage,
    currency
  };
  const { getCart } = context.extendQuery(
    customQuery,
    {
      getCart: {
        query: getCartDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  return context.client.query({
    query: gql`${getCart.query}`,
    variables: getCart.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });
}

export default getCart;
