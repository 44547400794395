import gql from 'graphql-tag';
import { getInventoryDefaultQuery } from './defaultQuery';
import { buildInventoryEntriesWhere } from '../../helpers/search';

import type { CustomQuery } from '@vue-storefront/core';
import type { InventoryEntryQueryResult } from '@vsf-enterprise/commercetools-types';
import type { Context } from '../../types/setup';

/**
 * Parameters for the `getInventory` API endpoint
 */
export interface GetInventoryParams {

  /**
   * Product SKU
   */
  sku: string | string[];

  /**
   * Product Supply Channel
   */
  supplyChannel?: string;
}

/**
 * Data returned from the `getInventory` API endpoint
 */
export type GetInventoryResponse = InventoryEntryQueryResult;

/**
 * Endpoint for checking inventory for given product SKUs. By default, it uses
 * the {@link getInventoryDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Inventory search parameters
 * @param customQuery - Custom queries included in the request
 * @returns Inventory data
 */
export async function getInventory(
  context: Context,
  params?: GetInventoryParams,
  customQuery?: CustomQuery
): Promise<GetInventoryResponse> {
  const { locale } = context.config;
  const variables = {
    where: buildInventoryEntriesWhere(context.config, params),
    locale
  };

  const { inventory } = context.extendQuery(
    customQuery,
    {
      inventory: {
        query: getInventoryDefaultQuery,
        variables
      }
    }
  );

  const response = await context.client.query({
    query: gql`${inventory.query}`,
    variables: inventory.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });

  // TODO: We should consider returning whole `response` object
  return response.data.inventoryEntries;
}
