import { ProductPriceFragment } from './product-price';
import { ChannelFragment } from './channel';

/**
 * Portion of the {@link LineItemFragment}
 */
export const DefaultLineItemFragment = `
  fragment DefaultLineItem on LineItem {
    id
    productId
    name(acceptLanguage: $acceptLanguage)
    productSlug(acceptLanguage: $acceptLanguage)
    quantity
    discountedPricePerQuantity {
      quantity
      discountedPrice {
        value {
          centAmount
          currencyCode
        }
        includedDiscounts {
          discount {
            name(acceptLanguage: $acceptLanguage)
            isActive
          }
        }
      }
    }
    variant {
      id
      sku
      price(currency: $currency) {
        tiers {
          value {
            centAmount
          }
        }
        value {
          centAmount
          currencyCode
        }
        discounted {
          value {
            centAmount
            currencyCode
          }
          discount {
            isActive
            name(acceptLanguage: $acceptLanguage)
          }
        }
      }
      images {
        url
        label
      }
      attributesRaw {
        name
        value
        attributeDefinition {
          type {
            name
          }
          label(locale: $locale)
        }
      }
    }
    price {
      ...DefaultProductPrice
    }
    distributionChannel {
      ...ChannelFragment
    }
    supplyChannel {
      ...ChannelFragment
    }
    shippingDetails {
      targets {
        addressKey
        quantity
      }
      valid
    }
  }
`;

/**
 * Reusable GraphQL fragment. It concatenates
 * {@link ProductPriceFragment} and {@link DefaultLineItemFragment}
 */
export const LineItemFragment = `
  ${ProductPriceFragment}
  ${ChannelFragment}

  ${DefaultLineItemFragment}
`;
