import { StoreFragment } from './store';

/**
 * Portion of the {@link StoreQueryResultFragment}
 */
export const DefaultStoreQueryResultFragment = `
  fragment StoreQueryResultFragment on StoreQueryResult {
    offset
    count
    total
    results {
      ...StoreFragment
    }
  }
`;

/**
 * Reusable GraphQL fragment. It concatenates
 * {@link StoreFragment} and {@link DefaultStoreQueryResultFragment}
 */
export const StoreQueryResultFragment = `
  ${StoreFragment}

  ${DefaultStoreQueryResultFragment}
`;
