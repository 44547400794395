import gql from 'graphql-tag';
import { setDefaultShippingAddressDefaultQuery } from './defaultQuery';

import type { CustomQuery } from '@vue-storefront/core';
import type { Customer } from '@vsf-enterprise/commercetools-types';
import type { Context } from '../../types/setup';

export const setDefaultShippingAddressAction = (addressId: string) => ({ setDefaultShippingAddress: { addressId } });

/**
 * Parameters for the `setDefaultShippingAddress` API endpoint
 */
export interface SetDefaultShippingAddressParams {

  /**
   * Shipping address ID
   */
  address: {
    id: string;
  };

  /**
   * User version
   */
  user: {
    version: string;
  };
}

/**
 * Data returned from the `setDefaultShippingAddress` API endpoint
 */
export type SetDefaultShippingAddressResponse = Customer;

/**
 * Endpoint for setting default shipping address of the currently logged user based on provided ID.
 * By default, it uses the {@link setDefaultShippingAddressDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - User and address information
 * @param customQuery - Custom queries included in the request
 * @returns Customer data
 */
export async function setDefaultShippingAddress(
  context: Context,
  params: SetDefaultShippingAddressParams,
  customQuery?: CustomQuery
): Promise<SetDefaultShippingAddressResponse> {
  const defaultVariables = {
    version: params.user.version,
    actions: [
      setDefaultShippingAddressAction(params.address.id)
    ]
  };

  const { setDefaultShippingAddress } = context.extendQuery(
    customQuery,
    {
      setDefaultShippingAddress: {
        query: setDefaultShippingAddressDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  const response = await context.client.mutate({
    mutation: gql`${setDefaultShippingAddress.query}`,
    variables: setDefaultShippingAddress.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });

  // TODO: We should consider returning whole `response` object
  return response.data.user;
}
