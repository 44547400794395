import { Config, CT_COOKIE_NAME, Token } from '../types/setup';
import { CookieOptions } from 'express';
import TokenParser from './tokenParser';
import type { TokenProvider } from '@commercetools/sdk-auth';

/**
 * Object with helpers for reading and creating access token cookies
 * and accessing current token provider
 */
export interface TokenManagerInterface {
  onTokenChange?: (token: Token) => void;
  onTokenRead?: () => Token;
  onTokenRemove?: () => void;
  setTokenProvider?: (token: Token) => void;
  getTokenProvider?: () => TokenProvider;
}

type CookieOptsResolver = (cookie: string, configuration: Config) => CookieOptions;

export const TokenManagerFactory = {
  create: (apolloContext: any, configuration: Config, cookieOptResolver: CookieOptsResolver): TokenManagerInterface => {
    const { req, res } = apolloContext;
    let currentToken = TokenParser.parseToken(req.cookies[CT_COOKIE_NAME]);
    let currentTokenProvider = {};

    /**
     * Set token provider.
     */
    const setTokenProvider = (tokenProvider) => {
      currentTokenProvider = tokenProvider;
    };

    /**
     * Get token provider.
     */
    const getTokenProvider = () => {
      return currentTokenProvider;
    };

    /**
     * Updates token cookie.
     */
    const onTokenChange = (token) => {
      currentToken = token;
      const options = {
        ...cookieOptResolver(CT_COOKIE_NAME, configuration),
        ...(token?.expires_at && { expires: new Date(token.expires_at) })
      } as CookieOptions;

      return res.cookie(
        CT_COOKIE_NAME,
        JSON.stringify(token),
        options
      );
    };

    /**
     * Returns current access token.
     */
    const onTokenRead = () => {
      return currentToken;
    };

    /**
     * Removes token cookie.
     */
    const onTokenRemove = () => {
      currentToken = null;
      return res.clearCookie(CT_COOKIE_NAME);
    };

    return {
      setTokenProvider,
      getTokenProvider,
      onTokenChange,
      onTokenRead,
      onTokenRemove
    };
  }
};

export default TokenManagerFactory;
