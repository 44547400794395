import { CustomerFragment } from '../../fragments';

/**
 * Portion of the GraphQL query used in the `updateShippingAddress` API endpoint
 */
export const updateShippingAddressMutation = `
  mutation updateShippingAddress($version: Long!, $actions: [MyCustomerUpdateAction!]!, $storeKey: KeyReferenceInput) {
    user: updateMyCustomer(version: $version, actions: $actions, storeKey: $storeKey) {
      ...DefaultCustomer
    }
  }
`;

/**
 * Default GraphQL query for the `updateShippingAddress` API endpoint. It concatenates
 * the {@link CustomerFragment} and {@link updateShippingAddressMutation}
 */
export const updateShippingAddressDefaultQuery = `
  ${CustomerFragment}

  ${updateShippingAddressMutation}
`;
