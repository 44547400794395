import { CustomerFragment } from '../../fragments';

/**
 * Portion of the GraphQL query used in the `addShippingAddress` API endpoint
 */

export const addShippingAddressMutation = `
  mutation addShippingAddress($version: Long!, $actions: [MyCustomerUpdateAction!]!, $storeKey: KeyReferenceInput) {
    user: updateMyCustomer(version: $version, actions: $actions, storeKey: $storeKey) {
      ...DefaultCustomer
    }
  }
`;

/**
 * Default GraphQL query for the `addShippingAddress` API endpoint. It concatenates
 * the {@link CustomerFragment} and {@link addShippingAddressMutation}
 */
export const addShippingAddressDefaultQuery = `
  ${CustomerFragment}

  ${addShippingAddressMutation}
`;
