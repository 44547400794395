import { cartActions } from '@vsf-enterprise/commercetools-api';
import { useCart } from '../useCart';
import { catchApiErrors } from '../helpers/internals/handleApiErrors';
import { ErrorResponse } from '../types';

import type { Address } from '@vsf-enterprise/commercetools-types';
import type { Context, CustomQuery } from '@vue-storefront/core';

type UseBillingMethods<BILLING, BILLING_PARAMS> = {
  provide: (context: Context) => any;
  load: (context: Context, params: { customQuery?: CustomQuery }) => Promise<BILLING>;
  save: (context: Context, params: { params: BILLING_PARAMS; billingDetails: BILLING; customQuery?: CustomQuery }) => Promise<BILLING>;
}

const useBillingMethods: UseBillingMethods<Address, any> = {
  provide: () => {
    return {
      cart: useCart()
    };
  },
  load: async (context: Context, { customQuery }) => {
    if (!context.cart.cart?.value?.billingAddress) {
      await context.cart.load({ customQuery });
    }

    return context.cart.cart.value.billingAddress;
  },
  save: async (context: Context, { billingDetails, customQuery }) => {
    const response = await context.$ct.api.updateCart({
      id: context.cart.cart.value.id,
      version: context.cart.cart.value.version,
      actions: [
        cartActions.setBillingAddressAction(billingDetails)
      ]
    }, customQuery);
    catchApiErrors(response as ErrorResponse);
    context.cart.setCart(response.data.cart);

    return context.cart.cart.value.billingAddress;
  }
};

export default useBillingMethods;
