import gql from 'graphql-tag';
import { Logger } from '@vue-storefront/core';
import { updateCartDefaultQuery } from './defaultQuery';
import { getStoreKey } from '../../helpers/utils';

import type { CustomQuery } from '@vue-storefront/core';
import type { Cart, MyCartUpdateAction } from '@vsf-enterprise/commercetools-types';
import type { Context, QueryResponse } from '../../types/setup';

const VERSION_MISMATCH_CODE = 'ConcurrentModification';

/**
 * Parameters for the `updateCart` API endpoint
 */
export interface UpdateCartParams {

  /**
   * Cart ID
   */
  id: string;

  /**
   * Cart version
   */
  version: string | number;

  /**
   * Operations to be performed on the cart
   */
  actions: MyCartUpdateAction[];

  /**
   * Should the request be retried if there was a cart version mismatch
   */
  versionFallback?: boolean;
}

/**
 * Data returned from the `updateCart` API endpoint
 */
export type UpdateCartResponse = QueryResponse<'cart', Cart>;

/**
 * Updates user cart using provided actions. By default, it uses
 * the {@link updateCartDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Cart and update actions information
 * @param customQuery - Custom queries included in the request
 * @returns User cart information
 */
export async function updateCart(
  context: Context,
  params: UpdateCartParams,
  customQuery?: CustomQuery
): Promise<UpdateCartResponse> {
  const { locale, acceptLanguage, currency, store } = context.config;

  const userVariables = params ? {
    locale,
    currency,
    ...params
  } : {};

  const defaultVariables = {
    ...userVariables,
    acceptLanguage,
    ...getStoreKey(store)
  };

  const { updateCart: updateCartGql } = context.extendQuery(
    customQuery,
    {
      updateCart: {
        query: updateCartDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  try {
    return await context.client.mutate({
      mutation: gql`${updateCartGql.query}`,
      variables: updateCartGql.variables,
      fetchPolicy: 'no-cache',
      context: {
        req: context.req,
        res: context.res
      }
    });
  } catch (error) {
    const canRetry = params.versionFallback ?? true;
    const causedByMismatch = error.graphQLErrors?.[0]?.code?.includes(VERSION_MISMATCH_CODE);
    const currentVersion = error.graphQLErrors?.[0]?.currentVersion;

    if (!causedByMismatch || !canRetry || !currentVersion) {
      throw error;
    }

    Logger.debug('Cart version mismatch. Retrying with current version.');

    return updateCart(context, { ...params, version: currentVersion }, customQuery);
  }
}
