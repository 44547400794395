import type { Context } from '../../types/setup';
import TokenManagerFactory from '../../token/TokenManagerFactory';
import getCookieOptions from '../../cookies/getCookieOptions';

/**
 * Endpoint for logging out the current user. It doesn't use any GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 */
export async function customerSignOut(
  context: Context
): Promise<void> {
  const { client } = context;

  const tokenManager = TokenManagerFactory.create({ req: context.req, res: context.res }, context.config, getCookieOptions);
  tokenManager.onTokenRemove();

  // TODO: We need to check if code below works anything and if `tokenProvider` exists on `client`

  // @ts-ignore
  if (client.tokenProvider) {
    // @ts-ignore
    client.tokenProvider.invalidateTokenInfo();
  }
}
