import gql from 'graphql-tag';
import { getShippingMethodsDefaultQuery } from './defaultQuery';

import type { CustomQuery } from '@vue-storefront/core';
import type { ShippingMethod } from '@vsf-enterprise/commercetools-types';
import type { Context, QueryResponse } from '../../types/setup';

/**
 * Data returned from the `getShippingMethods` API endpoint
 */
export type GetShippingMethodsResponse = QueryResponse<'shippingMethods', ShippingMethod[]>;

/**
 * Endpoints for fetching shipping methods available for the given cart. By default, it uses
 * the {@link getShippingMethodsDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param cartId - User cart ID
 * @param customQuery - Custom queries included in the request
 * @returns - Shipping methods data
 */
export async function getShippingMethods(
  context: Context,
  cartId?: string,
  customQuery?: CustomQuery
) {
  const { acceptLanguage } = context.config;
  const defaultVariables = {
    acceptLanguage, cartId
  };

  const { shippingMethods } = context.extendQuery(
    customQuery,
    {
      shippingMethods: {
        query: getShippingMethodsDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  return await context.client.query({
    query: gql`${shippingMethods.query}`,
    variables: shippingMethods.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });
}
