import { updateCart, UpdateCartResponse } from '../updateCart';
import { addDiscountCodeAction } from '../../helpers/actions/cart';

import type { CustomQuery } from '@vue-storefront/core';
import type { Context } from '../../types/setup';

/**
 * Parameters for the `applyCartCoupon` API endpoint
 */
export interface ApplyCartCouponParams {

  /**
   * Cart ID
   */
  id: string;

  /**
   * Cart version
   */
  version: string;
}

/**
 * Data returned from the `applyCartCoupon` API endpoint
 */
export type ApplyCartCouponResponse = UpdateCartResponse;

/**
 * Endpoint for applying discount code to the provided cart. By default, it uses
 * the {@link updateCartDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Cart information
 * @param discountCode - Discount (coupon) code
 * @param customQuery - Custom queries included in the request
 * @returns User cart information
 */
export async function applyCartCoupon(
  context: Context,
  params: ApplyCartCouponParams,
  discountCode: string,
  customQuery?: CustomQuery
): Promise<ApplyCartCouponResponse> {
  // TODO: We should consider merging the second and third parameters into one
  const { id, version } = params;

  return updateCart(context, {
    id,
    version,
    actions: [addDiscountCodeAction(discountCode)]
  }, customQuery);
}
