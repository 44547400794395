import { updateCart } from '../updateCart';
import { createChangeLineItemQuantityAction } from '../../helpers/actions/cart';

import type { CustomQuery } from '@vue-storefront/core';
import type { UpdateCartResponse } from '../updateCart';
import type { Context } from '../../types/setup';

/**
 * First parameter for the `updateCartQuantity` API endpoint
 */
export interface UpdateCartQuantityParams {

  /**
   * Cart ID
   */
  id: string;

  /**
   * Cart version
   */
  version: string;
}

/**
 * Second parameter for the `updateCartQuantity` API endpoint
 */
export interface UpdateCartQuantityProductParams {

  /**
   * Product ID
   */
  id: string;

  /**
   * Product quantity
   */
  quantity: number;
}

/**
 * Data returned from the `updateCartQuantity` API endpoint
 */
export type UpdateCartQuantityResponse = UpdateCartResponse;

/**
 * Endpoint for updating quantity of given product from the cart. By default, it uses
 * the {@link updateCartDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Cart information
 * @param product - Product information
 * @param customQuery - Custom queries included in the request
 * @returns User cart information
 */
export async function updateCartQuantity(
  context: Context,
  cart: UpdateCartQuantityParams,
  product: UpdateCartQuantityProductParams,
  customQuery?: CustomQuery
): Promise<UpdateCartQuantityResponse> {
  // TODO: We should consider merging the second and third parameters into one

  return await updateCart(
    context,
    {
      ...cart,
      actions: [createChangeLineItemQuantityAction(product)]
    },
    customQuery
  );
}
