import gql from 'graphql-tag';
import { getOrdersDefaultQuery } from './defaultQuery';
import { buildOrderWhere } from '../../helpers/search';

import type { CustomQuery } from '@vue-storefront/core';
import type { Me } from '@vsf-enterprise/commercetools-types';
import type { OrderWhereSearch } from '../../types/Api';
import type { Context, QueryResponse } from '../../types/setup';

/**
 * Parameters for the `getOrders` API endpoint
 */
export interface GetOrdersParams extends OrderWhereSearch {

  /**
   * Sorting options. You can read more {@link https://docs.commercetools.com/api/general-concepts#sorting | here}
   */
  sort?: string[];

  /**
   * Pagination limit
   */
  limit?: number;

  /**
   * Pagination offset
   */
  offset?: number;
}

/**
 * Data returned from the `getOrders` API endpoint
 */
export type GetOrdersResponse = QueryResponse<'me', Me>;

/**
 * Endpoint for fetching filtered, sorted, and paginated user orders. By default, it uses
 * the {@link getOrdersDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Search and pagination options
 * @param customQuery - Custom queries included in the request
 * @returns User orders data
 */
export async function getOrders(
  context: Context,
  params: GetOrdersParams,
  customQuery?: CustomQuery
) {
  const { locale, acceptLanguage, currency } = context.config;

  const defaultVariables = {
    where: buildOrderWhere(params),
    sort: params.sort,
    limit: params.limit,
    offset: params.offset,
    acceptLanguage,
    locale,
    currency
  };

  const { getMyOrders } = context.extendQuery(
    customQuery,
    {
      getMyOrders: {
        query: getOrdersDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  return context.client.query({
    query: gql`${getMyOrders.query}`,
    variables: getMyOrders.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });
}
