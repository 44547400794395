import gql from 'graphql-tag';
import { addReviewDefaultQuery } from './defaultQuery';

import type { CustomQuery } from '@vue-storefront/core';
import type { Review, ReviewDraft } from '@vsf-enterprise/commercetools-types';
import type { Context, QueryResponse } from '../../types/setup';

/**
 * Parameters for the `addMultipleToMyShoppingList` API endpoint
 */
export interface AddReviewParams {

  /**
   * ID of the parent product
   */
  productId: string;

  /**
   * Review information, such as author, rating, title and description
   */
  draft: ReviewDraft;

  /**
   * Pagination limit
   */
  limit?: number;

  /**
   * Pagination offset
   */
  offset?: number;

  /**
   * Sorting options. You can read more {@link https://docs.commercetools.com/api/general-concepts#sorting | here}
   */
  sort?: string;

  /**
   * Custom where query
   */
  where?: string;
}

/**
 * Data returned from the `addReview` API endpoint
 */
export type AddReviewResponse = QueryResponse<'review', Partial<Review>>;

/**
 * Endpoint for adding new product reviews. By default, it uses
 * the {@link addReviewDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Review and product details
 * @param customQuery - Custom queries included in the request
 * @returns Paginated product reviews
 */
export async function addReview(
  context: Context,
  params: AddReviewParams,
  customQuery?: CustomQuery
): Promise<AddReviewResponse> {
  const defaultVariables = {
    draft: {
      ...params.draft,
      target: {
        typeId: 'product',
        id: params.productId
      }
    }
  };

  const { addReview } = context.extendQuery(
    customQuery,
    {
      addReview: {
        query: addReviewDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  return await context.client.mutate({
    mutation: gql`${addReview.query}`,
    variables: addReview.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });
}
