import gql from 'graphql-tag';
import type { CustomQuery } from '@vue-storefront/core';
import type { Customer } from '@vsf-enterprise/commercetools-types';
import { customerUpdateMeDefaultQuery } from './defaultQuery';
import {
  changeCustomerEmailAction,
  setCustomerFirstNameAction,
  setCustomerLastNameAction,
  setCustomerDateOfBirthAction
} from '../../helpers/actions/customer';

import type { Context } from '../../types/setup';

/**
 * First parameter used in the `customerUpdateMe` API endpoint
 */
export interface CustomerUpdateCurrentUser {

  /**
   * Version of the user data
   */
  version: string | number;
}

/**
 * Second parameter used in the `customerUpdateMe` API endpoint
 */
export interface UpdatedUserData {

  /**
   * New user e-mail address
   */
  email: string;

  /**
   * New user first name
   */
  firstName: string;

  /**
   * New user last name
   */
  lastName: string;

  /**
   * New user date of birth
   */
  dateOfBirth?: string;
}

/**
 * Data returned from the `customerUpdateMe` endpoint
 */
export type CustomerUpdateMeResponse = {
  user: Customer
};

/**
 * Endpoint for updating data of the currently logged user. By default, it uses
 * the {@link customerUpdateMeDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param currentUser - Information about the current user
 * @param updatedUserData - New user information
 * @param customQuery - Custom queries included in the request
 * @returns User data
 */
export async function customerUpdateMe(
  context: Context,
  currentUser: CustomerUpdateCurrentUser,
  updatedUserData: UpdatedUserData,
  customQuery?: CustomQuery
): Promise<CustomerUpdateMeResponse> {
  // TODO: We should consider merging the second, third, and fourth parameters into one

  const { customerUpdateMe } = context.extendQuery(
    customQuery,
    {
      customerUpdateMe: {
        query: customerUpdateMeDefaultQuery,
        variables: {
          version: currentUser.version,
          actions: [
            changeCustomerEmailAction(updatedUserData.email),
            setCustomerFirstNameAction(updatedUserData.firstName),
            setCustomerLastNameAction(updatedUserData.lastName),
            setCustomerDateOfBirthAction(updatedUserData.dateOfBirth)
          ]
        }
      }
    }
  );

  const response = await context.client.mutate({
    mutation: gql`${customerUpdateMe.query}`,
    variables: customerUpdateMe.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });

  // TODO: We should consider returning whole `response` object
  return response.data;
}
