import { CartFragment } from './../../fragments';

/**
 * Portion of the GraphQL query used in the `deleteCart` API endpoint
 */
export const deleteCartMutation = `
  mutation deleteCart($id: String!, $version: Long!, $locale: Locale!, $acceptLanguage: [Locale!], $currency: Currency!) {
    cart: deleteMyCart(id: $id, version: $version) {
      ...DefaultCart
    }
  }
`;

/**
 * Default GraphQL query for the `deleteCart` API endpoint. It concatenates
 * the {@link CartFragment} and {@link deleteCartMutation}
 */
export const deleteCartDefaultQuery = `
  ${CartFragment}
  ${deleteCartMutation}
`;
