import { CustomerFragment } from '../../fragments';

/**
 * Portion of the GraphQL query used in the `customerChangeMyPassword` API endpoint
 */
export const customerChangeMyPasswordMutation = `
  mutation customerChangeMyPassword($version: Long!, $currentPassword: String!, $newPassword: String!, $storeKey: KeyReferenceInput) {
    user: customerChangeMyPassword(version: $version, currentPassword: $currentPassword, newPassword: $newPassword, storeKey: $storeKey) {
      ...DefaultCustomer
    }
  }
`;

/**
 * Default GraphQL query for the `customerChangeMyPassword` API endpoint. It concatenates
 * the {@link CustomerFragment} and {@link customerChangeMyPasswordMutation}
 */
export const customerChangeMyPasswordDefaultQuery = `
  ${CustomerFragment}

  ${customerChangeMyPasswordMutation}
`;
