import {AddressFragment} from './address';
import {LineItemFragment} from './line-item';

/**
 * Portion of the {@link OrderFragment}
 */

export const DefaultOrderFragment = `
  fragment DefaultOrder on Order {
    lineItems {
      ...DefaultLineItem
    }
    totalPrice {
      centAmount
      currencyCode
    }
    orderState
    id
    orderNumber
    version
    createdAt
    customerEmail
    shipmentState
    paymentState
    shippingAddress {
      ...DefaultAddress
    }
    billingAddress {
      ...DefaultAddress
    }
    cart {
      id
      version
    }
  }
`;

/**
 * Reusable GraphQL fragment. It concatenates
 * {@link AddressFragment}, {@link LineItemFragment} and {@link DefaultOrderFragment}
 */
export const OrderFragment = `
  ${AddressFragment}

  ${LineItemFragment}

  ${DefaultOrderFragment}
`;
