import gql from 'graphql-tag';
import { updateShippingAddressDefaultQuery } from './defaultQuery';
import { setDefaultShippingAddressAction } from '../setDefaultShippingAddress';

import type { CustomQuery } from '@vue-storefront/core';
import type { AddressInput, Customer } from '@vsf-enterprise/commercetools-types';
import type { Context } from '../../types/setup';

const updateAddressAction = (address: AddressInput) => ({ changeAddress: { addressId: address.id, address } });

/**
 * Parameters for the `updateShippingAddress` API endpoint
 */
export interface UpdateShippingAddressParams {

  /**
   * Updated shipping address
   */
  address: AddressInput & {
    isDefault?: boolean;
  };

  /**
   * Current user
   */
  user: {
    defaultShippingAddressId: string;
    version: string;
  };
}

/**
 * Data returned from the `updateShippingAddress` API endpoint
 */
export type UpdateShippingAddressResponse = Partial<Customer>;

/**
 * Endpoint for updating shipping address of the current user. By default, it uses
 * the {@link updateShippingAddressDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Address and user information
 * @param customQuery - Custom queries included in the request
 * @returns User information
 */
export async function updateShippingAddress(
  context: Context,
  params: UpdateShippingAddressParams,
  customQuery?: CustomQuery
): Promise<UpdateShippingAddressResponse> {
  const { isDefault, ...address } = params.address;
  const wasDefault = params.user.defaultShippingAddressId === address.id;

  const defaultVariables: any = {
    version: params.user.version,
    actions: [
      updateAddressAction(address)
    ]
  };

  if (wasDefault !== isDefault) {
    const addressId: any = wasDefault && !isDefault
      // Address is no longer a default
      ? null
      // Address is a new default
      : address.id;

    // "unshift" because setting the default must be before update
    defaultVariables.actions.unshift(setDefaultShippingAddressAction(addressId));
  }

  const { updateShippingAddress } = context.extendQuery(
    customQuery,
    {
      updateShippingAddress: {
        query: updateShippingAddressDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  const response = await context.client.mutate({
    mutation: gql`${updateShippingAddress.query}`,
    variables: updateShippingAddress.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });

  // TODO: We should consider returning whole `response` object
  return response.data.user;
}
