import { LocalizedStringFragment } from './localized-string';
import { AddressFragment } from './address';

/**
 * Portion of the {@link ChannelFragment}
 */
export const DefaultChannelFragment = `
  fragment ChannelFragment on Channel {
    id
    version
    key
    roles
    name(locale: $locale)
    description(locale: $locale)
    nameAllLocales {
      ...LocalizedStringFragment
    }
    descriptionAllLocales {
      ...LocalizedStringFragment
    }
    address {
      ...DefaultAddress
    }
  }
`;

/**
 * Reusable GraphQL fragment. It concatenates
 * {@link LocalizedStringFragment}, {@link AddressFragment} and {@link DefaultChannelFragment}
 */
export const ChannelFragment = `
  ${LocalizedStringFragment}

  ${AddressFragment}

  ${DefaultChannelFragment}
`;
