import { useCart } from '../useCart';
import { catchApiErrors } from '../helpers/internals/handleApiErrors';

import type { Context, CustomQuery } from '@vue-storefront/core';
import type { Order } from '@vsf-enterprise/commercetools-types';

type UseMakeOrderMethods<ORDER> = {
  provide: (context: Context) => any;
  make: (context: Context, params: { customQuery?: CustomQuery }) => Promise<ORDER>;
}

const useMakeOrderMethods: UseMakeOrderMethods<Order> = {
  provide() {
    return {
      cart: useCart()
    };
  },

  make: async (context: Context, { customQuery }): Promise<Order> => {
    const { id, version } = context.cart.cart.value;
    const response: any = await context.$ct.api.createMyOrderFromCart({ id, version }, customQuery);
    catchApiErrors(response);
    return response.data.order;
  }
};

export default useMakeOrderMethods;
