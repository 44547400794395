import { AddressFragment } from './address';
import { CustomerFragment } from './customer';
import { LineItemFragment } from './line-item';
import { ShippingMethodFragment } from './shipping-method';

/**
 * Portion of the {@link CartFragment}
 */
export const DefaultCartFragment = `
  fragment DefaultCart on Cart {
    id
    customerId
    customerEmail
    lineItems {
      ...DefaultLineItem
    }
    totalPrice {
      centAmount
      currencyCode
    }
    shippingAddress {
      ...DefaultAddress
    }
    billingAddress {
      ...DefaultAddress
    }
    customer {
      ...DefaultCustomer
    }
    totalPrice {
      centAmount
      currencyCode
    }
    taxedPrice {
      totalNet {
        centAmount
        currencyCode
      }
      totalGross {
        centAmount
        currencyCode
      }
    }
    paymentInfo {
      payments {
        id
      }
    }
    shippingInfo {
      price {
        centAmount
        currencyCode
      }
      shippingMethod {
        ...DefaultShippingMethod
      }
    }
    discountCodes {
      state
      discountCode {
        id
        code
        isActive
        validFrom
        validUntil
        name(acceptLanguage: $acceptLanguage)
      }
    }
    refusedGifts {
      isActive
      validFrom
      validUntil
      name(acceptLanguage: $acceptLanguage)
    }
    custom {
      customFieldsRaw {
        name
        value
      }
    }
    cartState
    version
    inventoryMode
  }
`;

/**
 * Reusable GraphQL fragment. It concatenates
 * {@link AddressFragment}, {@link CustomerFragment}, {@link LineItemFragment},
 * {@link ShippingMethodFragment} and {@link DefaultCartFragment}
 */
export const CartFragment = `
  ${AddressFragment}

  ${CustomerFragment}

  ${LineItemFragment}

  ${ShippingMethodFragment}

  ${DefaultCartFragment}
`;
