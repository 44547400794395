/**
 * Default GraphQL query for the `addReview` API endpoint
 */
export const addReviewDefaultQuery = `
  mutation createReview($draft: ReviewDraft!) {
    review: createReview(draft: $draft) {
      id
      text
      authorName
      rating
    }
  }
`;
