import useCartMethods from './useCartMethods';
import generateContext from '../helpers/context';
import { computed, Ref } from '@nuxtjs/composition-api';
import { Logger, sharedRef } from '@vue-storefront/core';

import type { Context } from '@vue-storefront/core';
import type { UseCart, UseCartErrors } from '../types';
import type { Cart, LineItem, ProductVariant } from '@vsf-enterprise/commercetools-types';

/**
 * Composable for managing user cart, including managing products and discount coupons
 */
function useCart(): UseCart<Partial<Cart>, LineItem, ProductVariant> {
  const context: Context = generateContext(useCartMethods);
  const loading: Ref<boolean> = sharedRef(false, 'useCart-loading');
  const cart: Ref<Partial<Cart>> = sharedRef(null, 'useCart-cart');
  const error: Ref<UseCartErrors> = sharedRef({
    addItem: null,
    removeItem: null,
    updateItemQty: null,
    load: null,
    clear: null,
    applyCoupon: null,
    removeCoupon: null,
    setItemSupplyChannel: null
  }, 'useCart-error');

  /**
   * Sets cart with given cart details
   */
  function setCart(newCart: Cart) {
    cart.value = newCart;
    Logger.debug('useCartFactory.setCart', newCart);
  }

  /**
   * Adds item to cart
   */
  async function addItem({ product, quantity, customQuery }) {
    Logger.debug('useCart.addItem', { product, quantity });

    try {
      loading.value = true;
      const updatedCart = await useCartMethods.addItem(context, {
        currentCart: cart.value,
        product,
        quantity,
        customQuery
      });
      error.value.addItem = null;
      cart.value = updatedCart;
    } catch (err) {
      error.value.addItem = err;
      Logger.error('useCart/addItem', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Removes product from cart
   */
  async function removeItem({ product, customQuery }) {
    Logger.debug('useCart.removeItem', { product });

    try {
      loading.value = true;
      const updatedCart = await useCartMethods.removeItem(context, {
        currentCart: cart.value,
        product,
        customQuery
      });
      error.value.removeItem = null;
      cart.value = updatedCart;
    } catch (err) {
      error.value.removeItem = err;
      Logger.error('useCart/removeItem', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Updates quantity of product in cart
   */
  async function updateItemQty({ product, quantity, customQuery }) {
    Logger.debug('useCart.updateItemQty', { product, quantity });

    if (quantity && quantity > 0) {
      try {
        loading.value = true;
        const updatedCart = await useCartMethods.updateItemQty(context, {
          currentCart: cart.value,
          product,
          quantity,
          customQuery
        });
        error.value.updateItemQty = null;
        cart.value = updatedCart;
      } catch (err) {
        error.value.updateItemQty = err;
        Logger.error('useCart/updateItemQty', err);
      } finally {
        loading.value = false;
      }
    }
  }

  /**
   * Loads cart
   */
  async function load({ customQuery, reload } = { customQuery: undefined, reload: false }) {
    Logger.debug('useCart.load');

    if (cart.value && !reload) {

      /**
       * Triggering change for hydration purpose,
       * temporary issue related with cpapi plugin
       */
      loading.value = false;
      error.value.load = null;
      cart.value = { ...cart.value };
      return;
    }
    try {
      loading.value = true;
      cart.value = await useCartMethods.load(context, { customQuery });
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useCart/load', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Clears cart
   */
  async function clear() {
    Logger.debug('useCart.clear');

    try {
      loading.value = true;
      await useCartMethods.clear(context, { currentCart: cart.value });
      error.value.clear = null;
      cart.value = null;
    } catch (err) {
      error.value.clear = err;
      Logger.error('useCart/clear', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Checks if product is in the cart
   */
  function isInCart({ product }) {
    return useCartMethods.isInCart(context, {
      currentCart: cart.value,
      product
    });
  }

  /**
   * Applies discount coupon
   */
  async function applyCoupon({ couponCode, customQuery }) {
    Logger.debug('useCart.applyCoupon', couponCode);

    try {
      loading.value = true;
      const { updatedCart } = await useCartMethods.applyCoupon(context, {
        currentCart: cart.value,
        couponCode,
        customQuery
      });
      error.value.applyCoupon = null;
      cart.value = updatedCart;
    } catch (err) {
      error.value.applyCoupon = err;
      Logger.error('useCart/applyCoupon', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Removes discount coupon
   */
  async function removeCoupon({ couponCode, customQuery }) {
    Logger.debug('useCart.removeCoupon', couponCode);

    try {
      loading.value = true;
      const { updatedCart } = await useCartMethods.removeCoupon(context, {
        currentCart: cart.value,
        couponCode,
        customQuery
      });
      error.value.removeCoupon = null;
      cart.value = updatedCart;
      loading.value = false;
    } catch (err) {
      error.value.removeCoupon = err;
      Logger.error('useCart/removeCoupon', err);
    } finally {
      loading.value = false;
    }
  }

  /**
   * Update Cart LineItem with SupplyChannel
   */
  async function setItemSupplyChannel({ product, customQuery }) {
    Logger.debug('useCart.setItemSupplyChannel', [product, customQuery]);
    try {
      loading.value = true;
      const { updatedCart } = await useCartMethods.setItemSupplyChannel(context, {
        currentCart: cart.value,
        product,
        customQuery
      });
      error.value.setItemSupplyChannel = null;
      cart.value = updatedCart;
      loading.value = false;
    } catch (err) {
      error.value.setItemSupplyChannel = err;
      Logger.error('useCart/setItemSupplyChannel', err);
    } finally {
      loading.value = false;
    }
  }

  return {
    setCart,
    cart: computed(() => cart.value),
    isInCart,
    addItem,
    load,
    removeItem,
    clear,
    updateItemQty,
    applyCoupon,
    removeCoupon,
    setItemSupplyChannel,
    loading: computed(() => loading.value),
    error: computed(() => error.value)
  };
}

export {
  useCart
};
