import gql from 'graphql-tag';
import { customerChangeMyPasswordDefaultQuery } from './defaultQuery';

import type { Customer } from '@vsf-enterprise/commercetools-types';
import type { Context, QueryResponse } from '../../types/setup';

/**
 * Data returned from the `customerChangeMyPassword` API endpoint
 */
export type CustomerChangeMyPasswordResponse = QueryResponse<'user', Customer>;

/**
 * Endpoint for changing password of the currently logged user. By default, it uses
 * the {@link customerChangeMyPasswordDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param version - Customer version
 * @param currentPassword - Current user password
 * @param newPassword - New user password
 * @returns User information
 */
export async function customerChangeMyPassword(
  context: Context,
  version: string,
  currentPassword: string,
  newPassword: string
): Promise<CustomerChangeMyPasswordResponse> {
  // TODO: We should consider merging the second, third, and fourth parameters into one
  // TODO: Add support for `customQuery`

  return await context.client.mutate({
    mutation: gql`${customerChangeMyPasswordDefaultQuery}`,
    variables: {
      version,
      currentPassword,
      newPassword
    },
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });
}
