import gql from 'graphql-tag';
import { customerCreatePasswordResetTokenDefaultQuery } from './defaultQuery';
import { Context, CustomQuery, Logger } from '@vue-storefront/core';
import { CustomerPasswordToken } from '@vsf-enterprise/commercetools-types';
import { QueryResponse } from '../../types/setup';

/**
 * Data returned from the `customerCreatePasswordResetToken` API endpoint
 */
export type CustomerCreatePasswordResetTokenResponse = QueryResponse<'customerCreatePasswordResetToken', CustomerPasswordToken>;

/**
 * Endpoint for request a token for resetting password for the user with given e-mail address.
 * By default, it uses the {@link customerCreatePasswordResetTokenDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param email - Customer email address
 * @param expose - Customer email address
 * @param customQuery - Custom queries included in the request
 * @returns Password reset token information
 */
export const customerCreatePasswordResetToken = async (
  context: Context,
  email: string,
  expose?: false,
  customQuery?: CustomQuery
): Promise<CustomerCreatePasswordResetTokenResponse | any> => {
  const { locale, acceptLanguage } = context.config;
  const defaultVariables = email
    ? {
      locale,
      acceptLanguage,
      email
    }
    : { acceptLanguage };
  const { customerCreatePasswordResetToken } = context.extendQuery(customQuery, {
    customerCreatePasswordResetToken: { query: customerCreatePasswordResetTokenDefaultQuery, variables: defaultVariables }
  });

  try {
    const response = (await context.client.mutate({
      mutation: gql`
        ${customerCreatePasswordResetToken.query}
      `,
      variables: customerCreatePasswordResetToken.variables,
      fetchPolicy: 'no-cache',
      context: {
        req: context.req,
        res: context.res
      }
    })) as CustomerCreatePasswordResetTokenResponse;
    return expose ? response : {};
  } catch (error) {
    Logger.error(`Cannot create password reset token. Error: ${error}`);
    throw process.env.NODE_ENV === 'development' ? error : {};
  }
};
