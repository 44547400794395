import { CookieOptions } from 'express';
import { Config, CT_COOKIE_NAME } from '../types/setup';

export const DEF_OPTS: CookieOptions = {
  path: '/',
  sameSite: 'lax'
};

const getDefTokenCookieOpts = (): CookieOptions => ({
  httpOnly: true,
  secure: true,
  sameSite: 'strict'
});

const getCookieOptions = (cookie = '', config?: Config): CookieOptions => {
  const customOptions = { ...(config && config?.cookie_options?.[cookie]) };

  switch (cookie) {
    case CT_COOKIE_NAME:
      return { ...getDefTokenCookieOpts(), ...customOptions };
    default:
      return DEF_OPTS;
  }
};

export default getCookieOptions;
