import { useCart } from '../useCart';
import { cartActions } from '@vsf-enterprise/commercetools-api';
import { ShippingMethod } from '@vsf-enterprise/commercetools-types';
import { ErrorResponse, ShippingProviderState } from '../types';
import { catchApiErrors } from '../helpers/internals/handleApiErrors';

import type { Ref } from '@nuxtjs/composition-api';
import type { Context, CustomQuery } from '@vue-storefront/core';

type UseShippingProviderMethods<STATE, SHIPPING_METHOD> = {
  provide: (context: Context) => any;
  load: (context: Context, params: { state: Ref<STATE>; customQuery?: CustomQuery }) => Promise<STATE>;
  save: (context: Context, params: { state: Ref<STATE>; shippingMethod: SHIPPING_METHOD; customQuery?: CustomQuery }) => Promise<STATE>;
};

const useShippingProviderMethods: UseShippingProviderMethods<ShippingProviderState, ShippingMethod> = {
  provide() {
    return {
      cart: useCart()
    };
  },
  load: async (context: Context, { customQuery, state }) => {
    if (!context.cart.cart?.value?.shippingInfo) {
      await context.cart.load({ customQuery });
    }
    return {
      ...state.value,
      response: context.cart.cart.value.shippingInfo
    };
  },
  save: async (context: Context, { shippingMethod, customQuery, state }) => {
    const response = await context.$ct.api.updateCart(
      {
        id: context.cart.cart.value.id,
        version: context.cart.cart.value.version,
        actions: [cartActions.setShippingMethodAction(shippingMethod.id)]
      },
      customQuery
    );
    catchApiErrors(response as unknown as ErrorResponse);
    context.cart.setCart(response.data.cart);

    return {
      ...state.value,
      response: context.cart.cart.value.shippingInfo
    };
  }
};

export default useShippingProviderMethods;
