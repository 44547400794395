import { OrderFragment } from '../../fragments';

/**
 * Portion of the GraphQL query used in the `createMyOrderFromCart` API endpoint
 */
export const createMyOrderFromCartMutation = `
  mutation createMyOrderFromCart($draft: OrderMyCartCommand!, $locale: Locale!, $acceptLanguage: [Locale!], $currency: Currency!, $storeKey: KeyReferenceInput) {
    order: createMyOrderFromCart(draft: $draft, storeKey: $storeKey) {
      ...DefaultOrder
    }
  }
`;

/**
 * Default GraphQL query for the `createMyOrderFromCart` API endpoint. It concatenates
 * the {@link OrderFragment} and {@link createMyOrderFromCartMutation}
 */
export const createMyOrderFromCartDefaultQuery = `
  ${OrderFragment}

  ${createMyOrderFromCartMutation}
`;
