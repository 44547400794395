import { updateCart, UpdateCartResponse } from '../updateCart';
import { removeDiscountCodeAction } from '../../helpers/actions/cart';

import type { CustomQuery } from '@vue-storefront/core';
import type { ReferenceInput } from '@vsf-enterprise/commercetools-types';
import type { Context } from '../../types/setup';

/**
 * Parameters for the `removeCartCoupon` API endpoint
 */
export interface RemoveCartCouponParams {

  /**
   * Cart ID
   */
  id: string;

  /**
   * Cart version
   */
  version: string;
}

/**
 * Data returned from the `removeCartCoupon` API endpoint
 */
export type RemoveCartCouponResponse = UpdateCartResponse;

/**
 * Endpoint for removing coupon code from cart. By default, it uses
 * the {@link updateCartDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Cart information
 * @param discountCode - Discount code
 * @param customQuery - Custom queries included in the request
 * @returns User cart information
 */
export async function removeCartCoupon(
  context: Context,
  params: RemoveCartCouponParams,
  discountCode: ReferenceInput,
  customQuery?: CustomQuery
): Promise<RemoveCartCouponResponse> {
  // TODO: We should consider merging the second and third parameters into one

  return updateCart(
    context,
    {
      ...params,
      actions: [
        removeDiscountCodeAction(discountCode)
      ]
    },
    customQuery
  );
}
