/**
 * Default GraphQL query for the `createMyShoppingList` API endpoint
 */
export const createMyShoppingListDefaultQuery = `
  mutation createMyShoppingList($draft: MyShoppingListDraft!, $acceptLanguage: [Locale!], $currency: Currency!, $country: Country!) {
    wishlist: createMyShoppingList(draft: $draft) {
      id
      version
      lineItems {
        id
        quantity
        name(acceptLanguage: $acceptLanguage)
        productSlug(acceptLanguage: $acceptLanguage)
        variant {
          sku
          price (currency: $currency, country: $country) {
            tiers {
              value {
                centAmount
              }
            }
            value {
              centAmount
              currencyCode
            }
            discounted {
              value {
                centAmount
                currencyCode
              }
              discount {
                isActive
                name(acceptLanguage: $acceptLanguage)
              }
            }
          }
          images {
            url
            label
          }
        }
      }
    }
  }
`;
