import { ChannelFragment } from './../../fragments';

/**
 * Portion of the GraphQL query used in the `getFacetProductProjection` API endpoint
 */
export const productProjectionsSearchQuery = `
  fragment DefaultProductPriceSearch on ProductPriceSearch {
    discounted {
      value {
        type
        currencyCode
        centAmount
        fractionDigits
      }
      discount {
        validFrom
        validUntil
        isActive
        name(acceptLanguage: $acceptLanguage)
      }
    }
    value {
      type
      currencyCode
      centAmount
      fractionDigits
    }
  }
  fragment ImagesProductSearch on ProductSearchVariant {
    images {
      url
      label
    }
  }
  fragment PriceProductSearch on ProductSearchVariant {
    price(currency: $currency, country: $country, channelId: $channelId) {
      ...DefaultProductPriceSearch
    }
  }
  fragment AttributesProductSearch on ProductSearchVariant {
    attributesRaw {
      name
      value
    }
  }
  fragment AvailabilityProductSearch on ProductSearchVariant {
    availability {
      noChannel {
        isOnStock
        restockableInDays
        availableQuantity
      }
      channels(
        includeChannelIds: $includeChannelIds 
        excludeChannelIds: $excludeChannelIds 
        limit: $channelLimit
        offset: $channelOffset
      ) {
        limit
        offset
        total
        results {
          channelRef {
            id
          }
          availability {
            isOnStock
            restockableInDays
            availableQuantity
          }
          channel {
            ...ChannelFragment
          }
        }
      }
    }
  }
  fragment DefaultVariantProductSearch on ProductSearchVariant {
    id
    sku
    ...ImagesProductSearch
    ...PriceProductSearch
    ...AttributesProductSearch
    ...AvailabilityProductSearch
  }
  query productProjectionsSearch(
    $sorts: [String!],
    $limit: Int,
    $offset: Int,
    $locale: Locale!,
    $acceptLanguage: [Locale!],
    $text: String,
    $facets: [SearchFacetInput!],
    $filters: [SearchFilterInput!],
    $queryFilters: [SearchFilterInput!],
    $facetFilters: [SearchFilterInput!],
    $currency: Currency!,
    $country: Country,
    $channelId: String,
    $includeChannelIds: [String!],
    $excludeChannelIds:[String!],
    $channelLimit: Int,
    $channelOffset: Int
  ) {
    productProjectionSearch(
      sorts: $sorts,
      limit: $limit,
      offset: $offset,
      locale: $locale,
      text: $text,
      facets: $facets
      filters: $filters,
      queryFilters: $queryFilters,
      facetFilters: $facetFilters
    ) {
      count
      total
      offset
      results {
        id
        slug(acceptLanguage: $acceptLanguage)
        name(acceptLanguage: $acceptLanguage)
        masterVariant {
          ...DefaultVariantProductSearch
        }
        variants {
          ...DefaultVariantProductSearch
        }
        reviewRatingStatistics {
          averageRating,
          ratingsDistribution,
          count
        }
      }
      facets {
        facet,
        value {
          ... on TermsFacetResult {
            terms {
              term
              count
              productCount
            }
          }
        }
      }
    }
  }
`;

/**
 * Default GraphQL query for the `getFacetProductProjection` API endpoint. It concatenates
 * the {@link ChannelFragment} and {@link productProjectionsSearchQuery}
 */
export const getFacetProductProjectionDefaultQuery = `
  ${ChannelFragment}

  ${productProjectionsSearchQuery}
`;
