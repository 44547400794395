/**
 * Default GraphQL query for the `addMultipleToMyShoppingList` API endpoint
 */
export const addMultipleToMyShoppingListDefaultQuery = `
  mutation updateShoppingList($id: String!, $version: Long!, $actions: [MyShoppingListUpdateAction!]!, $acceptLanguage: [Locale!], $currency: Currency!, $country: Country!) {
    wishlist: updateMyShoppingList(id: $id, version: $version, actions: $actions) {
      id
      version
      lineItems {
        id
        quantity
        name(acceptLanguage: $acceptLanguage)
        variant {
          sku
          price(currency: $currency, country: $country) {
            tiers {
              value {
                centAmount
              }
            }
            value {
              centAmount
              currencyCode
            }
            discounted {
              value {
                centAmount
                currencyCode
              }
              discount {
                isActive
                name(acceptLanguage: $acceptLanguage)
              }
            }
          }
          images {
            url
            label
          }
        }
      }
    }
  }
`;
