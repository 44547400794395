import gql from 'graphql-tag';
import { deleteCartDefaultQuery } from './defaultQuery';

import type { CustomQuery } from '@vue-storefront/core';
import type { Cart } from '@vsf-enterprise/commercetools-types';
import type { Context, QueryResponse } from '../../types/setup';

/**
 * Parameters for the `deleteCart` API endpoint
 */
export interface DeleteCartParams {

  /**
   * Cart ID
   */
  id: string;

  /**
   * Cart version
   */
  version: string;
}

/**
 * Data returned from the `deleteCart` API endpoint
 */
export type DeleteCartResponse = QueryResponse<'cart', Cart>;

/**
 * Endpoint for deleting cart of the currently logged user based on parameters. By default, it uses
 * the {@link deleteCartDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Cart information
 * @param customQuery - Custom queries included in the request
 * @returns Cart data
 */
export async function deleteCart(
  context: Context,
  params: DeleteCartParams,
  customQuery?: CustomQuery
): Promise<DeleteCartResponse> {
  const { id, version } = params;
  const { locale, acceptLanguage, currency } = context.config;

  const defaultVariables = {
    id,
    version,
    acceptLanguage,
    locale,
    currency
  };

  const { deleteCart: deleteCartGql } = context.extendQuery(
    customQuery,
    {
      deleteCart: {
        query: deleteCartDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  return await context.client.mutate({
    mutation: gql`${deleteCartGql.query}`,
    variables: deleteCartGql.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });
}
