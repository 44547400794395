import gql from 'graphql-tag';
import { getReviewDefaultQuery } from './defaultQuery';
import { buildReviewWhere } from '../../helpers/search';

import type { CustomQuery } from '@vue-storefront/core';
import type { ReviewQueryResult } from '@vsf-enterprise/commercetools-types';
import type { Context } from '../../types/setup';

/**
 * Parameters for the `getReview` API endpoint
 */
export interface GetReviewParams {

  /**
   * ID of the parent product
   */
  productId?: string;

  /**
   * Pagination limit
   */
  limit?: number;

  /**
   * Pagination offset
   */
  offset?: number;

  /**
   * Sorting options. You can read more {@link https://docs.commercetools.com/api/general-concepts#sorting | here}
   */
  sort?: string;

  /**
   * Custom where query
   */
  where?: string;
}

/**
 * Data returned from the `getReview` API endpoint
 */
export type GetReviewResponse = ReviewQueryResult & {
  limit: number
};

/**
 * Endpoint for fetching paginated reviews for the provided product. By default, it uses
 * the {@link getReviewDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Product information and pagination options
 * @param customQuery - Custom queries included in the request
 * @returns Reviews data
 */
export async function getReview(
  context: Context,
  params: GetReviewParams,
  customQuery?: CustomQuery
): Promise<GetReviewResponse> {
  const defaultVariables = {
    where: buildReviewWhere(params),
    limit: params.limit,
    offset: params.offset,
    sort: params.sort
  };

  const { reviews } = context.extendQuery(
    customQuery,
    {
      reviews: {
        query: getReviewDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  const request = await context.client.query({
    query: gql`${reviews.query}`,
    variables: reviews.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });

  // TODO: We should consider returning only the whole `response` object
  return {
    ...request.data.reviews,
    limit: params.limit || 0
  };
}
