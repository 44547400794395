import { CustomerFragment } from '../../fragments';

/**
 * Portion of the GraphQL query used in the `setDefaultBillingAddress` API endpoint
 */
export const setDefaultBillingAddressMutation = `
  mutation setDefaultBillingAddress($version: Long!, $actions: [MyCustomerUpdateAction!]!, $storeKey: KeyReferenceInput) {
    user: updateMyCustomer(version: $version, actions: $actions, storeKey: $storeKey) {
      ...DefaultCustomer
    }
  }
`;

/**
 * Default GraphQL query for the `setDefaultBillingAddress` API endpoint. It concatenates
 * the {@link CustomerFragment} and {@link setDefaultBillingAddressMutation}
 */
export const setDefaultBillingAddressDefaultQuery = `
  ${CustomerFragment}

  ${setDefaultBillingAddressMutation}
`;
