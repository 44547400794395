import { Token } from '../types/setup';

const parseToken = (rawToken: string): Token => {
  try {
    return JSON.parse(rawToken);
  } catch {
    return null;
  }
};

const TokenParser = {
  parseToken
};

export default TokenParser;
