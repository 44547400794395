import { ShippingMethodFragment } from '../../fragments';

/**
 * Portion of the GraphQL query used in the `getShippingMethods` API endpoint
 */
export const shippingMethodsQuery = `
  query shippingMethods($acceptLanguage: [Locale!], $cartId: String!) {
    shippingMethods: shippingMethodsByCart(id: $cartId) {
      ...DefaultShippingMethod
    }
  }
`;

/**
 * Default GraphQL query for the `getShippingMethods` API endpoint. It concatenates
 * the {@link ShippingMethodFragment} and {@link shippingMethodsQuery}
 */
export const getShippingMethodsDefaultQuery = `
  ${ShippingMethodFragment}

  ${shippingMethodsQuery}
`;
