import gql from 'graphql-tag';
import { addShippingAddressDefaultQuery } from './defaultQuery';
import { setDefaultShippingAddressAction } from '../setDefaultShippingAddress';

import type { CustomQuery } from '@vue-storefront/core';
import type { AddressInput, Customer } from '@vsf-enterprise/commercetools-types';
import type { Context } from '../../types/setup';

const addAddressAction = (address: AddressInput) => ({ addAddress: { address } });
const setAsShippingAddressAction = (addressId: string) => ({ addShippingAddressId: { addressId } });

/**
 * Parameters for the `addShippingAddress` API endpoint
 */
export interface AddShippingAddressParams {

  /**
   * New shipping address
   */
  address: AddressInput & {
    isDefault?: boolean;
  };

  /**
   * Current user
   */
  user: {
    version: string;
  };
}

/**
 * Data returned from the `addShippingAddress` API endpoint
 */
export type AddShippingAddressResponse = Partial<Customer>;

/**
 * Endpoint for adding new shipping address for the current user. By default, it uses
 * the {@link addShippingAddressDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Address information and user version
 * @param customQuery - Custom queries included in the request
 * @returns User information
 */
export async function addShippingAddress(
  context: Context,
  params: AddShippingAddressParams,
  customQuery?: CustomQuery
): Promise<AddShippingAddressResponse> {
  const { isDefault, ...address } = params.address;
  const defaultVariables = {
    version: params.user.version,
    actions: [
      addAddressAction(address)
    ]
  };

  const { addShippingAddress } = context.extendQuery(
    customQuery,
    {
      addShippingAddress: {
        query: addShippingAddressDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  const addAddressRequest = await context.client.mutate({
    mutation: gql`${addShippingAddress.query}`,
    variables: addShippingAddress.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });

  const newAddress = addAddressRequest.data.user.addresses[addAddressRequest.data.user.addresses.length - 1];

  const actions: any[] = [
    setAsShippingAddressAction(newAddress.id)
  ];

  if (isDefault) {
    actions.push(setDefaultShippingAddressAction(newAddress.id));
  }

  const response = await context.client.mutate({
    mutation: gql`${addShippingAddress.query}`,
    variables: {
      version: addAddressRequest.data.user.version,
      actions
    },
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });

  // TODO: We should consider returning whole `response` object
  return response.data.user;
}
