import { CustomerFragment } from '../../fragments';

/**
 * Portion of the GraphQL query used in the `customerResetPassword` API endpoint
 */
export const customerResetPasswordMutation = `
  mutation customerResetPassword($tokenValue: String!, $newPassword: String!, $storeKey: KeyReferenceInput) {
    customerResetPassword(tokenValue: $tokenValue, newPassword: $newPassword, storeKey: $storeKey) {
      ...DefaultCustomer
    }
  }
`;

/**
 * Default GraphQL query for the `customerResetPassword` API endpoint. It concatenates
 * the {@link CustomerFragment} and {@link customerResetPasswordMutation}
 */
export const customerResetPasswordDefaultQuery = `
  ${CustomerFragment}

  ${customerResetPasswordMutation}
`;
