
const filterStrategyMap = {
  filter: 'filters',
  query: 'queryFilters',
  facets: 'facetFilters'
};

export const getFiltersPerStrategy = (facetParams: Record<string, any>, filter: Record<string, any>) => {
  const { availableFacets, filteringStrategy } = facetParams;
  return filter
    .flatMap(curr => {
      const facetConfig = availableFacets.find(({ facet }) => curr.startsWith(`${facet}:`));
      let strategy = facetConfig?.filteringStrategy || filteringStrategy || 'filter';
      if (!Array.isArray(strategy)) {
        strategy = [strategy];
      }
      return strategy.map(name => {
        const strategyName = filterStrategyMap[name];
        return {
          filter: curr,
          strategyName
        };
      });
    })
    .reduce((total, { strategyName, filter }) => {
      const strategy = total[strategyName];
      const strategyFilter = [{ string: filter }];
      return {
        ...total,
        [strategyName]: strategy ? strategy.concat(strategyFilter) : strategyFilter
      };
    }, []);
};

const buildFacetParams = ({ facet, option, name, countingProducts }) => {
  if (!facet) {
    throw new Error('Missing "facet" value to build facet params.');
  }
  let facetsParam = facet.toString();
  if (option) {
    facetsParam += `:${option}`;
  }
  if (name) {
    facetsParam += ` as ${name}`;
  }
  if (countingProducts) {
    facetsParam += ' counting products';
  }
  return {
    string: facetsParam
  };
};

export const getFacets = ({ availableFacets }: Record<string, any>) => {
  return availableFacets.map(buildFacetParams);
};

export const getSorts = ({ sortingOptions }: Record<string, any>, sort: string) => {
  const sortOption = sortingOptions.find(s => s.id === sort);
  return sortOption && `${sortOption.facet} ${sortOption.direction}`;
};

