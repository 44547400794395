import { CustomerFragment } from '../../fragments';

/**
 * Portion of the GraphQL query used in the `addBillingAddress` API endpoint
 */
export const addBillingAddressMutation = `
  mutation addBillingAddress($version: Long!, $actions: [MyCustomerUpdateAction!]!, $storeKey: KeyReferenceInput) {
    user: updateMyCustomer(version: $version, actions: $actions, storeKey: $storeKey) {
      ...DefaultCustomer
    }
  }
`;

/**
 * Default GraphQL query for the `addBillingAddress` API endpoint. It concatenates
 * the {@link CustomerFragment} and {@link addBillingAddressMutation}
 */
export const addBillingAddressDefaultQuery = `
  ${CustomerFragment}

  ${addBillingAddressMutation}
`;
