import gql from 'graphql-tag';
import { Logger } from '@vue-storefront/core';
import { customerResetPasswordDefaultQuery } from './defaultQuery';

import type { CustomQuery } from '@vue-storefront/core';
import type { Customer } from '@vsf-enterprise/commercetools-types';
import type { Context, QueryResponse } from '../../types/setup';

/**
 * Data returned from the `customerResetPassword` API endpoint
 */
export type CustomerResetPasswordResponse = QueryResponse<'customerResetPassword', Customer>;

/**
 * Endpoint for resetting password for the user associated with given token. By default, it uses
 * the {@link customerResetPasswordDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param tokenValue - Token for resetting user password returned from the `customerCreatePasswordResetToken` API endpoint
 * @param newPassword - New user password
 * @param customQuery - Custom queries included in the request
 * @returns Customer data
 */
export async function customerResetPassword(
  context: Context,
  tokenValue: string,
  newPassword: string,
  customQuery?: CustomQuery
): Promise<CustomerResetPasswordResponse> {
  const { locale, acceptLanguage } = context.config;
  const defaultVariables = tokenValue && newPassword
    ? {
      locale,
      acceptLanguage,
      tokenValue,
      newPassword
    }
    : { acceptLanguage };

  const { customerResetPassword } = context.extendQuery(
    customQuery,
    {
      customerResetPassword: {
        query: customerResetPasswordDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  try {
    return await context.client.mutate({
      mutation: gql`${customerResetPassword.query}`,
      variables: customerResetPassword.variables,
      fetchPolicy: 'no-cache',
      context: {
        req: context.req,
        res: context.res
      }
    });

  } catch (error) {
    Logger.error(`Cannot set new password after reset. Error: ${error}`);
    throw error;
  }
}
