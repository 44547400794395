import gql from 'graphql-tag';
import { customerSignMeUpDefaultQuery } from './defaultQuery';

import type { CustomQuery } from '@vue-storefront/core';
import type { CustomerSignMeUpDraft } from '@vsf-enterprise/commercetools-types';
import type { CustomerSignMeInResponse } from '../customerSignMeIn';
import type { Context } from '../../types/setup';

/**
 * Data returned from the `customerSignMeUp` API endpoint
 */
export type CustomerSignMeUpResponse = CustomerSignMeInResponse;

/**
 * Endpoint for registering a new user. By default, it uses
 * the {@link customerSignMeUpDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param draft - User information
 * @returns User data
 */
export async function customerSignMeUp(context: Context, draft: CustomerSignMeUpDraft, customQuery?: CustomQuery): Promise<CustomerSignMeUpResponse> {
  const { locale, acceptLanguage, currency } = context.config;
  const { customerSignMeUp } = context.extendQuery(customQuery, {
    customerSignMeUp: {
      query: customerSignMeUpDefaultQuery,
      variables: {
        draft,
        locale,
        acceptLanguage,
        currency
      }
    }
  });

  return await context.client.mutate({
    mutation: gql`
      ${customerSignMeUp.query}
    `,
    variables: customerSignMeUp.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });
}
