import gql from 'graphql-tag';
import { getMeBasicProfileDefaultQuery, getMeFullProfileDefaultQuery } from './defaultQuery';

import type { CustomQuery } from '@vue-storefront/core';
import type { Me } from '@vsf-enterprise/commercetools-types';
import type { Context, QueryResponse } from '../../types/setup';

/**
 * Parameters for the `getMe` API endpoint
 */
export interface GetMeParams {
  customer?: boolean;
}

/**
 * Data returned from the `getMe` API endpoint
 */
export type GetMeResponse = QueryResponse<'me', Me>;

/**
 * Endpoint for retrieving data about current user. By default, it uses
 * the {@link getMeBasicProfileDefaultQuery} GraphQL query when `customer` parameter is not passed
 * and the {@link getMeFullProfileDefaultQuery} GraphQL query when it's passed
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Parameters defining whether user is logged in
 * @param customQuery - Custom queries included in the request
 * @returns User data
 */
export async function getMe(
  context: Context,
  params: GetMeParams = {},
  customQuery?: CustomQuery
): Promise<GetMeResponse> {
  const { locale, acceptLanguage, currency } = context.config;
  const { customer }: GetMeParams = params;

  const defaultVariables = {
    locale,
    acceptLanguage,
    currency
  };

  const { getBasicProfile, getFullProfile } = context.extendQuery(
    customQuery,
    {
      getBasicProfile: {
        query: getMeBasicProfileDefaultQuery,
        variables: defaultVariables
      },
      getFullProfile: {
        query: getMeFullProfileDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  const profile = customer ? getFullProfile : getBasicProfile;

  return context.client.query({
    query: gql`${profile.query}`,
    variables: profile.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });
}
