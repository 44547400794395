import gql from 'graphql-tag';
import { customerSignMeInDefaultQuery } from './defaultQuery';

import type { CustomQuery } from '@vue-storefront/core';
import type { CustomerSignMeInDraft, Customer } from '@vsf-enterprise/commercetools-types';
import type { Context, QueryResponse } from '../../types/setup';

/**
 * Data returned from the `customerSignMeIn` API endpoint
 */
export type CustomerSignMeInResponse = QueryResponse<'user', Customer>;

/**
 * Endpoint for logging in a user. By default, it uses
 * the {@link customerSignMeInDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param draft - User credentials
 * @returns User information
 */
export async function customerSignMeIn(context: Context, draft: CustomerSignMeInDraft, customQuery?: CustomQuery): Promise<CustomerSignMeInResponse> {
  const { locale, acceptLanguage, currency } = context.config;
  const { customerSignMeIn } = context.extendQuery(customQuery, {
    customerSignMeIn: {
      query: customerSignMeInDefaultQuery,
      variables: {
        draft,
        locale,
        acceptLanguage,
        currency
      }
    }
  });

  return await context.client.mutate({
    mutation: gql`
      ${customerSignMeIn.query}
    `,
    variables: customerSignMeIn.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });
}
