import { updateCart, UpdateCartResponse } from './../updateCart';
import { createAddLineItemAction } from '../../helpers/actions/cart';

import type { CustomQuery } from '@vue-storefront/core';
import type { ProductVariant } from '@vsf-enterprise/commercetools-types';
import type { Context } from '../../types/setup';

/**
 * First parameter for the `addToCart` API endpoint
 */
export interface AddToCartParams {

  /**
   * Cart ID
   */
  id: string;

  /**
   * Cart version
   */
  version: string;
}

/**
 * Second parameter for the `addToCart` API endpoint
 */
export interface AddToCartProductParams {

  /**
   * Product to be added to the cart
   */
  product: ProductVariant;

  /**
   * Quantity of product added to the cart
   */
  quantity: number;

  /**
   * Current supply channel
   */
  supplyChannel?: string;

  /**
   * Current distribution channel
   */
  distributionChannel?: string;
}

/**
 * Data returned from the `addBillingAddress` API endpoint
 */
export type AddToCartResponse = UpdateCartResponse;

/**
 * Endpoint for adding product to the provided cart. By default, it uses
 * the {@link updateCartDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param cart - Cart object
 * @param params - Product, quantity and channel information
 * @param customQuery - Custom queries included in the request
 * @returns User cart information
 */
export async function addToCart(
  context: Context,
  cart: AddToCartParams,
  params: AddToCartProductParams,
  customQuery?: CustomQuery
): Promise<AddToCartResponse> {
  // TODO: We should consider merging the second and third parameters into one

  return updateCart(
    context,
    {
      ...cart,
      actions: [
        createAddLineItemAction(params)
      ]
    },
    customQuery
  );
}
