import type { FetchResult } from '@apollo/client/core';
import type { CustomQuery } from '@vue-storefront/core';
import type {
  Address,
  AddressInput,
  Cart,
  CartDraft,
  Customer,
  CustomerPasswordToken,
  CustomerSignInResult,
  CustomerSignMeInDraft,
  CustomerSignMeUpDraft,
  LineItem,
  Me,
  MyShoppingListDraft,
  Order,
  ReferenceInput,
  Review,
  ReviewDraft,
  ShippingMethod,
  ShoppingList
} from '@vsf-enterprise/commercetools-types';
import type { Context, QueryResponse, Token } from './setup';
import type { GetMeParams, GetMeResponse } from '../api/getMe';
import type { GetStoresParams, GetStoresResponse } from '../api/getStores';
import type {
  AddBillingAddressParams,
  AddBillingAddressResponse,
  AddMultipleToMyShoppingListParams,
  AddMultipleToMyShoppingListResponse,
  AddReviewParams,
  AddReviewResponse,
  AddShippingAddressParams,
  AddShippingAddressResponse,
  AddToCartParams,
  AddToCartProductParams,
  AddToCartResponse,
  AddToMyShoppingListParams,
  AddToMyShoppingListResponse,
  ApplyCartCouponParams,
  ApplyCartCouponResponse,
  CreateCartParams,
  CreateCartResponse,
  CreateMyOrderFromCartParams,
  CreateMyOrderFromCartResponse,
  CreateMyShoppingListParams,
  CreateMyShoppingListResponse,
  CustomerChangeMyPasswordResponse,
  CustomerCreatePasswordResetTokenResponse,
  CustomerResetPasswordResponse,
  CustomerSignMeInResponse,
  CustomerSignMeUpResponse,
  CustomerUpdateCurrentUser,
  CustomerUpdateMeResponse,
  DeleteBillingAddressParams,
  DeleteBillingAddressResponse,
  DeleteCartParams,
  DeleteCartResponse,
  DeleteShippingAddressParams,
  DeleteShippingAddressResponse,
  GetCartResponse,
  GetCategoryResponse,
  GetChannelParams,
  GetChannelResponse,
  GetFacetCategoriesResponse,
  GetFacetProductProjectionParams,
  GetFacetProductProjectionResponse,
  GetInventoryParams,
  GetInventoryResponse,
  GetMyShoppingListResponse,
  GetOrdersParams,
  GetOrdersResponse,
  GetProductResponse,
  GetReviewParams,
  GetReviewResponse,
  GetShippingMethodsResponse,
  RemoveCartCouponParams,
  RemoveCartCouponResponse,
  RemoveFromCartParams,
  RemoveFromCartResponse,
  RemoveFromMyShoppingListParams,
  RemoveFromMyShoppingListResponse,
  SetDefaultBillingAddressParams,
  SetDefaultBillingAddressResponse,
  SetDefaultShippingAddressParams,
  SetDefaultShippingAddressResponse,
  UpdateBillingAddressParams,
  UpdateBillingAddressResponse,
  UpdateCartParams,
  UpdateCartQuantityParams,
  UpdateCartQuantityProductParams,
  UpdateCartQuantityResponse,
  UpdateCartResponse,
  UpdatedUserData,
  UpdateShippingAddressParams,
  UpdateShippingAddressResponse,
  UpdateShippingDetailsParams,
  UpdateShippingDetailsResponse
} from '../api/types';
import {
  UpdateCartItemChannelParams,
  UpdateCartItemChannelProductParams,
  UpdateCartItemChannelResponse
} from '../api/updateCartItemChannel';

export interface BaseSearch {
  limit?: number;
  offset?: number;
  sort?: string[];
}

export enum AttributeType {
  STRING = 'StringAttribute',
  DATE = 'DateAttribute',
  DATETIME = 'DateTimeAttribute',
  TIME = 'TimeAttribute',
  NUMBER = 'NumberAttribute',
  ENUM = 'EnumAttribute',
  LOCALIZED_ENUM = 'LocalizedEnumAttribute',
  LOCALIZED_STRING = 'LocalizedStringAttribute',
  MONEY = 'MoneyAttribute',
  BOOLEAN = 'BooleanAttribute'
}

export interface Filter {
  type: AttributeType;
  name: string;
  value: any;
}

export interface ProductWhereSearch extends BaseSearch {
  catId?: string | string[];
  skus?: string[];
  slug?: string;
  id?: string;
  ids?: string[];
  key?: string;
  filters?: Filter[];
  customFilters?: string;
}

export interface FilterOption {
  label: string;
  value: string | number | boolean | [number, number] | [string, string];
  selected: boolean;
}

export interface CategoryWhereSearch extends BaseSearch {
  catId?: string;
  catIds?: string[],
  key?: string;
  slug?: string;
  slugs?: string[],
  ancestor?: string
  onlyParent?: string;
}

export interface OrderWhereSearch extends BaseSearch {
  id?: string;
  orderNumber?: string;
}

/**
 * @deprecated Use `GetChannelParams` interface instead
 */
export interface ChannelsWhereSearch extends BaseSearch {
  id?: string;
}

export interface FlowOptions {
  currentToken?: Token;
  customerCredentials?: {
    username: string;
    password: string;
  };
  requireUserSession?: boolean;
}

/**
 * @deprecated Use `CreateCartParams` interface instead
 */
export interface CartData extends Omit<CartDraft, 'currency'> {
  currency?: string;
}

/**
 * @deprecated Use `CreateMyShoppingListResponse` type instead
 */
export interface ShoppingListData extends Omit<MyShoppingListDraft, 'currency'> {
  currency?: string;
}

export interface ProductIdentifier {
  sku: string;
  [key: string]: any;
}

export interface LineItemIdentifier {
  id: string;
  [key: string]: any;
}

/**
 * @deprecated Use `AddReviewResponse` interface instead
 */
export type ReviewResponse = {
  results: Review[],
  total: number;
  limit: number;
  offset: number;
  averageRating: number;
  ratingsDistribution: {
    [rating: number]: number;
  };
}

/**
 * @deprecated Use `GetReviewParams` interface instead
 */
export type ReviewSearchParams = {
  productId: string;
  limit?: number;
  offset?: number;
}

/**
 * @deprecated Use `GetInventoryParams` interface instead
 */
export type InventorySearchParams = {
  productId: string;
  limit?: number;
  offset?: number;
}

/**
 * @deprecated Use `AddReviewParams` interface instead
 */
export type ReviewAddParams = {
  productId: string;
  limit?: number;
  offset?: number;
  draft: ReviewDraft;
};

/**
 * @deprecated Use `GetChannelParams` interface instead
 */
export type ChannelSearchParams = {
  id?: string;
  limit?: number;
  offset?: number;
}

/**
 * @deprecated Use `Customer` type instead
 */
export type User = Customer;

/**
 * @deprecated This type is no longer used
 */
type AddressParams<ADDRESS, USER> = {
  address: ADDRESS;
  user: USER;
}

/**
 * @deprecated Use `Address` from the `@vsf-enterprise/commercetools-types` package instead
 */
export type ShippingAddress = Address & {
  isDefault?: boolean;
}

/**
 * @deprecated Use `CreateMyShoppingListResponse` type instead
 */
export type WishlistResponse = {
  data: {
    wishlist?: ShoppingList;
    me?: Me;
  }
}

/**
 * @deprecated Use `AddShippingAddressParams` interface instead
 */
export type ShippingAddressAddParams = AddressParams<ShippingAddress, User>;

/**
 * @deprecated Use `DeleteShippingAddressParams` interface instead
 */
export type ShippingAddressDeleteParams = AddressParams<Pick<ShippingAddress, 'id'>, User>;

/**
 * @deprecated Use `SetDefaultShippingAddressParams` interface instead
 */
export type ShippingAddressSetDefaultParams = ShippingAddressDeleteParams;

/**
 * @deprecated Use `UpdateShippingAddressParams` interface instead
 */
export type ShippingAddressUpdateParams = ShippingAddressAddParams;

/**
 * @deprecated Use `Address` from the `@vsf-enterprise/commercetools-types` package instead
 */
export type BillingAddress = ShippingAddress;

/**
 * API parameters needed for adding new user billing address.
 */
export type BillingAddressAddParams = {
  address: AddressInput & {
    isDefault?: boolean;
  };
  user: {
    version: Customer['version'];
  }
}

/**
 * @deprecated Use `DeleteBillingAddressParams` interface instead
 */
export type BillingAddressDeleteParams = AddressParams<Pick<BillingAddress, 'id'>, Customer>;

/**
 * @deprecated Use `UpdateBillingAddressParams` interface instead
 */
export type BillingAddressUpdateParams = BillingAddressAddParams;

/**
 * @deprecated Use `SetDefaultBillingAddressParams` interface instead
 */
export type BillingAddressSetDefaultParams = BillingAddressDeleteParams;

/**
 * @deprecated This type is no longer used
 */
export type ApiResponseWrapper <KEY extends string, T> = Record<KEY, T>;

/**
 * @deprecated This type is no longer used
 */
export type MutationResponse<K extends string, V> = FetchResult<Record<K, V>>;

/**
 * @deprecated Use `GetCartResponse` interface instead
 */
export type CartQueryResponse = QueryResponse<'cart', Cart>;

/**
 * @deprecated This type is not used anymore
 */
export type OrderQueryResponse = QueryResponse<'order', Order>;

/**
 * @deprecated This type is not used anymore
 */
export type CartMutationResponse = MutationResponse<'cart', Cart>;

/**
 * @deprecated Use `UpdateCartResponse`, `UpdateCartQuantityResponse` or `UpdateShippingDetailsResponse` type instead
 */
export type CartResponse = CartQueryResponse | CartMutationResponse;

/**
 * @deprecated Use `CreateMyOrderFromCartResponse` type instead
 */
export type OrderMutationResponse = MutationResponse<'order', Order>;

/**
 * @deprecated This type is not used anymore
 */
export type OrderResponse = OrderQueryResponse | OrderMutationResponse;

/**
 * @deprecated This type is not used anymore
 */
export type ShippingMethodsResponse = QueryResponse<'shippingMethods', ShippingMethod>;

/**
 * @deprecated Use `CustomerSignMeInResponse` type instead
 */
export type SignInResponse = QueryResponse<'user', CustomerSignInResult>;

/**
 * @deprecated Use `CustomerChangeMyPasswordResponse` type instead
 */
export type ChangeMyPasswordResponse = QueryResponse<'user', Customer>;

/**
 * @deprecated Use `DeleteCartParams`, `RemoveFromCartParams`, `updateCartQuantity`, `AddToCartParams` or `ApplyCartCouponParams` interface instead
 */
export type CartDetails = Pick<Cart, 'id' | 'version'>;

/**
 * @deprecated Use `CustomerCreatePasswordResetTokenResponse` type instead
 */
export type CreatePasswordResetTokenResponse = QueryResponse<'customerCreatePasswordResetToken', CustomerPasswordToken>;

/**
 * @deprecated Use `CustomerResetPasswordResponse` type instead
 */
export type ResetPasswordResponse = QueryResponse<'customerResetPassword', Customer>;

/**
 * @deprecated Use `AddShippingAddressResponse`, `SetDefaultShippingAddressResponse` or `UpdateShippingAddressResponse` type instead
 */
export type ShippingAddressResponse = QueryResponse<'shippingAddress', User>;

/**
 * @deprecated Use `DeleteBillingAddressResponse`, `SetDefaultBillingAddressResponse` or `UpdateBillingAddressResponse` type instead
 */
export type BillingAddressResponse = QueryResponse<'billingAddress', User>;

/**
 * All API endpoints available in the integration out of the box
 */
export interface ApiMethods {

  /** See the {@link addBillingAddress} function */
  addBillingAddress(params: AddBillingAddressParams, customQuery?: CustomQuery): Promise<AddBillingAddressResponse>;

  /** See the {@link addMultipleToMyShoppingList} function */
  addMultipleToMyShoppingList(params: AddMultipleToMyShoppingListParams, customQuery?: CustomQuery): Promise<AddMultipleToMyShoppingListResponse>;

  /** See the {@link addReview} function */
  addReview(params: AddReviewParams, customQuery?: CustomQuery): Promise<AddReviewResponse>;

  /** See the {@link addShippingAddress} function */
  addShippingAddress(params: AddShippingAddressParams, customQuery?: CustomQuery): Promise<AddShippingAddressResponse>;

  /** See the {@link addToCart} function */
  addToCart(cart: AddToCartParams, product: AddToCartProductParams, customQuery?: CustomQuery): Promise<AddToCartResponse>;

  /** See the {@link addToMyShoppingList} function */
  addToMyShoppingList(params: AddToMyShoppingListParams, customQuery?: CustomQuery): Promise<AddToMyShoppingListResponse>;

  /** See the {@link applyCartCoupon} function */
  applyCartCoupon(params: ApplyCartCouponParams, discountCode: string, customQuery?: CustomQuery): Promise<ApplyCartCouponResponse>;

  /** See the {@link createCart} function */
  createCart(cartDraft?: CreateCartParams, customQuery?: CustomQuery): Promise<CreateCartResponse>;

  /** See the {@link createMyOrderFromCart} function */
  createMyOrderFromCart(draft: CreateMyOrderFromCartParams, customQuery?: CustomQuery): Promise<CreateMyOrderFromCartResponse>;

  /** See the {@link createMyShoppingList} function */
  createMyShoppingList(draft: CreateMyShoppingListParams, customQuery?: CustomQuery): Promise<CreateMyShoppingListResponse>;

  /** See the {@link customerChangeMyPassword} function */
  customerChangeMyPassword(version: string, currentPassword: string, newPassword: string): Promise<CustomerChangeMyPasswordResponse>;

  /** See the {@link customerCreatePasswordResetToken} function */
  customerCreatePasswordResetToken(email: string, expose: boolean, customQuery?: CustomQuery): Promise<CustomerCreatePasswordResetTokenResponse>;

  /** See the {@link customerResetPassword} function */
  customerResetPassword(tokenValue: string, newPassword: string, customQuery?: CustomQuery): Promise<CustomerResetPasswordResponse>;

  /** See the {@link customerSignMeIn} function */
  customerSignMeIn(draft: CustomerSignMeInDraft): Promise<CustomerSignMeInResponse>;

  /** See the {@link customerSignMeUp} function */
  customerSignMeUp(draft: CustomerSignMeUpDraft): Promise<CustomerSignMeUpResponse>;

  /** See the {@link customerSignOut} function */
  customerSignOut(): Promise<void>;

  /** See the {@link customerUpdateMe} function */
  customerUpdateMe(currentUser: CustomerUpdateCurrentUser, updatedUserData: UpdatedUserData, customQuery?: CustomQuery): Promise<CustomerUpdateMeResponse>;

  /** See the {@link deleteBillingAddress} function */
  deleteBillingAddress(params: DeleteBillingAddressParams, customQuery?: CustomQuery): Promise<DeleteBillingAddressResponse>;

  /** See the {@link deleteCart} function */
  deleteCart(params: DeleteCartParams, customQuery?: CustomQuery): Promise<DeleteCartResponse>;

  /** See the {@link deleteShippingAddress} function */
  deleteShippingAddress(params: DeleteShippingAddressParams, customQuery?: CustomQuery): Promise<DeleteShippingAddressResponse>;

  /** See the {@link getCart} function */
  getCart(cartId: string): Promise<GetCartResponse>;

  /** See the {@link getCategory} function */
  getCategory(params: CategoryWhereSearch, customQuery?: CustomQuery): Promise<GetCategoryResponse>;

  /** See the {@link getChannel} function */
  getChannel(params: GetChannelParams): Promise<GetChannelResponse>;

  /** See the {@link getFacetCategories} function */
  getFacetCategories(params: CategoryWhereSearch, customQuery?: CustomQuery): Promise<GetFacetCategoriesResponse>;

  /** See the {@link getFacetProductProjection} function */
  getFacetProductProjection(params: GetFacetProductProjectionParams, customQuery?: CustomQuery): Promise<GetFacetProductProjectionResponse>;

  /** See the {@link getInventory} function */
  getInventory(params: GetInventoryParams, customQuery?: CustomQuery): Promise<GetInventoryResponse>;

  /** See the {@link getMe} function */
  getMe(params?: GetMeParams, customQuery?: CustomQuery): Promise<GetMeResponse>;

  /** See the {@link getMyShoppingList} function */
  getMyShoppingList(customQuery?: CustomQuery): Promise<GetMyShoppingListResponse>;

  /** See the {@link getOrders} function */
  getOrders(params: GetOrdersParams, customQuery?: CustomQuery): Promise<GetOrdersResponse>;

  /** See the {@link getProduct} function */
  getProduct(params: ProductWhereSearch, customQuery?: CustomQuery): Promise<GetProductResponse>;

  /** See the {@link getReview} function */
  getReview (params: GetReviewParams, customQuery?: CustomQuery): Promise<GetReviewResponse>;

  /** See the {@link getShippingMethods} function */
  getShippingMethods (cartId?: string, customQuery?: CustomQuery): Promise<GetShippingMethodsResponse>;

  /** See the {@link getStores} function */
  getStores(params: GetStoresParams): Promise<GetStoresResponse>;

  /** See the {@link isGuest} function */
  isGuest(): Promise<boolean>;

  /** See the {@link isLoggedIn} function */
  isLoggedIn(): Promise<boolean>;

  /** See the {@link removeCartCoupon} function */
  removeCartCoupon(params: RemoveCartCouponParams, discountCode: ReferenceInput, customQuery?: CustomQuery): Promise<RemoveCartCouponResponse>;

  /** See the {@link removeFromCart} function */
  removeFromCart(cart: RemoveFromCartParams, product: LineItem, customQuery?: CustomQuery): Promise<RemoveFromCartResponse>;

  /** See the {@link removeFromMyShoppingList} function */
  removeFromMyShoppingList(params: RemoveFromMyShoppingListParams, customQuery?: CustomQuery): Promise<RemoveFromMyShoppingListResponse>;

  /** See the {@link setDefaultBillingAddress} function */
  setDefaultBillingAddress(params: SetDefaultBillingAddressParams, customQuery?: CustomQuery): Promise<SetDefaultBillingAddressResponse>;

  /** See the {@link setDefaultShippingAddress} function */
  setDefaultShippingAddress(params: SetDefaultShippingAddressParams, customQuery?: CustomQuery): Promise<SetDefaultShippingAddressResponse>;

  /** See the {@link updateBillingAddress} function */
  updateBillingAddress(params: UpdateBillingAddressParams, customQuery?: CustomQuery): Promise<UpdateBillingAddressResponse>;

  /** See the {@link updateCart} function */
  updateCart(params: UpdateCartParams, customQuery?: CustomQuery): Promise<UpdateCartResponse>;

  /** See the {@link updateCartQuantity} function */
  updateCartQuantity(cart: UpdateCartQuantityParams, product: UpdateCartQuantityProductParams, customQuery?: CustomQuery): Promise<UpdateCartQuantityResponse>;

  /** See the {@link updateShippingAddress} function */
  updateShippingAddress(params: UpdateShippingAddressParams, customQuery?: CustomQuery): Promise<UpdateShippingAddressResponse>;

  /** See the {@link updateShippingDetails} function */
  updateShippingDetails(cart: UpdateShippingDetailsParams, shippingDetails: AddressInput, customQuery?: CustomQuery): Promise<UpdateShippingDetailsResponse>;

  /** See the {@link updateCartItemChannel} function */
  updateCartItemChannel(cart: UpdateCartItemChannelParams, product: UpdateCartItemChannelProductParams, customQuery?: CustomQuery): Promise<UpdateCartItemChannelResponse>;
}

/**
 * @internal
 */
export type _CommercetoolsMethods = {
  [K in keyof ApiMethods]: (context: Context, ...args: [...Parameters<ApiMethods[K]>]) => ReturnType<ApiMethods[K]>;
}
