/**
 * Default GraphQL query for the `getFacetCategories` API endpoint
 */
export const getFacetCategoriesDefaultQuery = `
  fragment category on Category {
    id
    key
    slug(acceptLanguage: $acceptLanguage)
    name(acceptLanguage: $acceptLanguage)
  }
  
  query categories($where: String, $sort: [String!], $limit: Int, $offset: Int, $acceptLanguage: [Locale!]) {
    categories(where: $where, sort: $sort, limit: $limit, offset: $offset) {
      results {
        ...category
        metaDescription(acceptLanguage: $acceptLanguage)
        ancestors {
          ...category
          ancestors {
            ...category
          }
        }
        parent {
          id
          parent {
            id
            parent {
              id
            }
          }
        }
      }
    }
  }
`;
