import { StoreQueryResultFragment } from './../../fragments';

/**
 * Portion of the GraphQL query used in the `getStores` API endpoint
 */
export const storesQuery = `
  query stores(
    $where: String
    $sort: [String!]
    $limit: Int
    $offset: Int
    $locale: Locale!
  ) {
    stores(
      where: $where
      sort: $sort
      limit: $limit
      offset: $offset
    ) {
      ...StoreQueryResultFragment
    }
  }
`;

/**
 * Default GraphQL query for the `getStores` API endpoint. It concatenates
 * the {@link StoreQueryResultFragment} and {@link storesQuery}
 */
export const getStoresDefaultQuery = `
  ${StoreQueryResultFragment}

  ${storesQuery}
`;
