import gql from 'graphql-tag';
import { addToMyShoppingListDefaultQuery } from '../addToMyShoppingList/defaultQuery';

import type { CustomQuery } from '@vue-storefront/core';
import type { RemoveShoppingListLineItem, ShoppingList } from '@vsf-enterprise/commercetools-types';
import type { LineItemIdentifier } from '../../types/Api';
import type { Context, QueryResponse } from '../../types/setup';

const removeLineItemAction = (product: LineItemIdentifier): { removeLineItem: RemoveShoppingListLineItem } => ({
  removeLineItem: {
    quantity: 1,
    lineItemId: product.id
  }
});

/**
 * Parameters for the `removeFromMyShoppingList` API endpoint
 */
export interface RemoveFromMyShoppingListParams {

  /**
   * Array of products IDs
   */
  products: LineItemIdentifier[];

  /**
   * Wishlist ID
   */
  id: string;

  // Wishlist version
  version: string;
}

/**
 * Data returned from the `removeFromMyShoppingList` API endpoint
 */
export type RemoveFromMyShoppingListResponse = QueryResponse<'wishlist', ShoppingList>;

/**
 * Endpoint for removing products from the wishlist. By default, it uses
 * the {@link addToMyShoppingListDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Product IDs and cart information
 * @param customQuery - Custom queries included in the request
 * @returns User wishlist
 */
export async function removeFromMyShoppingList(
  context: Context,
  params: RemoveFromMyShoppingListParams,
  customQuery?: CustomQuery
): Promise<RemoveFromMyShoppingListResponse> {
  const { acceptLanguage, currency, country } = context.config;
  const { products, id, version } = params;

  const defaultVariables = {
    acceptLanguage,
    currency,
    country,
    id,
    version,
    actions: products.map(product => removeLineItemAction(product))
  };

  const { updateShoppingList } = context.extendQuery(
    customQuery,
    {
      updateShoppingList: {
        query: addToMyShoppingListDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  return await context.client.mutate({
    mutation: gql`${updateShoppingList.query}`,
    variables: updateShoppingList.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });
}
