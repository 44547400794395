import { updateCart } from '../updateCart';
import { setShippingAddressAction } from '../../helpers/actions/cart';

import type { CustomQuery } from '@vue-storefront/core';
import type { AddressInput } from '@vsf-enterprise/commercetools-types';
import type { UpdateCartResponse } from '../updateCart';
import type { Context } from '../../types/setup';

/**
 * Parameters for the `updateShippingDetails` API endpoint
 */
export interface UpdateShippingDetailsParams {

  /**
   * Cart ID
   */
  id: string;

  /**
   * Cart version
   */
  version: string;
}

/**
 * Data returned from the `updateShippingDetails` API endpoint
 */
export type UpdateShippingDetailsResponse = UpdateCartResponse;

/**
 * Updated shipping address details on the given cart. By default, it uses
 * the {@link updateCartDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param cart - Cart information
 * @param shippingDetails - Shipping address information
 * @param customQuery - Custom queries included in the request
 * @returns User cart information
 */
export async function updateShippingDetails(
  context: Context,
  cart: UpdateShippingDetailsParams,
  shippingDetails: AddressInput,
  customQuery?: CustomQuery
): Promise<UpdateShippingDetailsResponse> {
  return updateCart(
    context,
    {
      id: cart.id,
      version: cart.version,
      actions: [
        setShippingAddressAction(shippingDetails)
      ]
    },
    customQuery
  );
}
