import { CustomerFragment, CartFragment } from '../../fragments';

/**
 * Portion of the GraphQL query used in the `customerSignMeUp` API endpoint
 */
export const customerSignMeUpMutation = `
  mutation customerSignMeUp($draft: CustomerSignMeUpDraft!, $locale: Locale!, $acceptLanguage: [Locale!], $currency: Currency!, $storeKey: KeyReferenceInput) {
    user: customerSignMeUp(draft: $draft, storeKey: $storeKey) {
      customer {
        ...DefaultCustomer
      }
      cart {
        ...DefaultCart
      }
    }
  }
`;

/**
 * Default GraphQL query for the `customerSignMeUp` API endpoint. It concatenates
 * the {@link CustomerFragment}, {@link CartFragment} and {@link customerSignMeUpMutation}
 */
export const customerSignMeUpDefaultQuery = `
  ${CustomerFragment}

  ${CartFragment}

  ${customerSignMeUpMutation}
`;
