import { Channel } from '@vsf-enterprise/commercetools-types';
import { GetChannelParams } from '@vsf-enterprise/commercetools-api';
import { getFilters, getCategories, getCategoriesWithCount } from './_utils';
import { useChannel } from '../useChannel';
import { catchApiErrors } from '../helpers/internals/handleApiErrors';
import type { Context, FacetSearchResult, AgnosticFacetSearchParams } from '@vue-storefront/core';
import type { ErrorResponse, FacetResultsData, UseChannel } from '../types';

type UseFacetMethods<SEARCH_DATA> = {
  provide: (context: Context) => { channel: UseChannel<Channel[], GetChannelParams, any> };
  search: (context: Context, params?: FacetSearchResult<SEARCH_DATA>) => Promise<SEARCH_DATA>;
};

const useFacetMethods: UseFacetMethods<FacetResultsData> = {
  provide: () => ({
    channel: useChannel()
  }),
  search: async (context: Context, params: FacetSearchResult<AgnosticFacetSearchParams>) : Promise<FacetResultsData> => {
    const { faceting, enableChannelFilter } = context.$ct.config;
    const { page, itemsPerPage, categorySlug, rootCatSlug, filters, sort, phrase, categorySortDirection, customQuery, limit, ...customParams } = params.input;
    const channel = context.channel?.channel.value;
    const isChannelFilteringEnabled = channel && enableChannelFilter;

    const { queryFilters, rawFilters } = await getFilters(context, {
      category: { categorySlug, rootCatSlug },
      ...(isChannelFilteringEnabled && { availability: [channel] }),
      ...filters
    });
    const response = await context.$ct.api.getFacetProductProjection({
      page: page || 1,
      perPage: itemsPerPage,
      filter: queryFilters,
      phrase,
      sort,
      facetParams: faceting,
      ...(isChannelFilteringEnabled && { channelId: channel, includeChannelIds: [channel] }),
      customParams
    }, customQuery);
    catchApiErrors(response as unknown as ErrorResponse);
    const { productProjectionSearch: productsResult } = response.data;
    const categoriesResult = await getCategories(context, { productsResult, rawFilters, sortDirection: categorySortDirection, limit });
    const rootCategory = rawFilters.category?.root;
    const currentCategory = rawFilters.category?.current;
    const categories = getCategoriesWithCount(categoriesResult, productsResult.facets);

    return {
      results: productsResult.results,
      categories,
      facets: productsResult.facets,
      rootCategory,
      currentCategory,
      total: productsResult.total
    };
  }
};

export default useFacetMethods;
