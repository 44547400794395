import gql from 'graphql-tag';
import { getFacetCategoriesDefaultQuery } from './defaultQuery';
import { buildCategoryWhere } from '../../helpers/search';

import type { CustomQuery } from '@vue-storefront/core';
import type { CategoryQueryResult } from '@vsf-enterprise/commercetools-types';
import type { CategoryWhereSearch } from '../../types/Api';
import type { Context, QueryResponse } from '../../types/setup';

/**
 * Data returned from the `getFacetCategories` API endpoint
 */
export type GetFacetCategoriesResponse = QueryResponse<'categories', CategoryQueryResult>;

/**
 * Endpoint for loading categories based on filter parameters. By default, it uses
 * the {@link getFacetCategoriesDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Filtering and pagination options
 * @param customQuery - Custom queries included in the request
 * @returns Paginated categories data
 */
export async function getFacetCategories(
  context: Context,
  params: CategoryWhereSearch,
  customQuery?: CustomQuery
): Promise<GetFacetCategoriesResponse> {
  const { acceptLanguage } = context.config;
  const defaultVariables = params ? {
    where: buildCategoryWhere(context.config, params),
    limit: params.limit,
    offset: params.offset,
    sort: ['orderHint asc'],
    acceptLanguage
  } : { acceptLanguage };

  const { categories } = context.extendQuery(
    customQuery,
    {
      categories: {
        query: getFacetCategoriesDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  return await context.client.query({
    query: gql`${categories.query}`,
    variables: categories.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });
}
