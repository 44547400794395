import gql from 'graphql-tag';
import { getMyShoppingListDefaultQuery } from './defaultQuery';

import type { CustomQuery } from '@vue-storefront/core';
import type { MeQueryInterface } from '@vsf-enterprise/commercetools-types';
import type { Context, QueryResponse } from '../../types/setup';

/**
 * Data returned from the `getMyShoppingList` API endpoint
 */
export type GetMyShoppingListResponse = QueryResponse<'me', MeQueryInterface>;

/**
 * Endpoint for retrieving wishlist for a current user. By default, it uses
 * the {@link getMyShoppingListDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param customQuery - Custom queries included in the request
 * @returns - User data
 */
export async function getMyShoppingList(
  context: Context,
  customQuery?: CustomQuery
): Promise<GetMyShoppingListResponse> {
  const { acceptLanguage, currency, country } = context.config;
  const defaultVariables = {
    acceptLanguage,
    currency,
    country
  };

  const { getMe } = context.extendQuery(
    customQuery,
    {
      getMe: {
        query: getMyShoppingListDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  return context.client.query({
    query: gql`${getMe.query}`,
    variables: getMe.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });
}
