import gql from 'graphql-tag';
import { CustomQuery } from '@vue-storefront/core';
import { getChannelDefaultQuery } from './defaultQuery';
import { buildChannelsWhere } from '../../helpers/search';

import type { ChannelQueryResult } from '@vsf-enterprise/commercetools-types';
import type { Context } from '../../types/setup';

/**
 * Parameters for the `getChannel` API endpoint
 */
export interface GetChannelParams {

  /**
   * Channel ID
   */
  id: string;

  // TODO: Was this added here by mistake? In all other endpoints, `customQuery` is a third parameter, not part of `params` object
  /**
   * Custom Query
   */
  customQuery?: CustomQuery;
}

/**
 * Data returned from the `getChannel` API endpoint
 */
export type GetChannelResponse = ChannelQueryResult;

/**
 * Endpoint for fetching a list of channels. By default, it uses
 * the {@link getChannelDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Channel information
 * @returns Channel data
 */
export async function getChannel(
  context: Context,
  params?: GetChannelParams
): Promise<GetChannelResponse> {
  const { acceptLanguage } = context.config;
  const predicate = buildChannelsWhere(params);
  const variables = predicate.length > 0
    ? {
      where: predicate,
      acceptLanguage
    }
    : { acceptLanguage };

  const { customQuery } = Object(params);

  const { getChannelsData } = context.extendQuery(
    customQuery,
    {
      getChannelsData: {
        query: getChannelDefaultQuery,
        variables
      }
    }
  );

  const response = await context.client.query({
    query: gql`${getChannelsData.query}`,
    variables: getChannelsData.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });

  // TODO: We should consider returning whole `response` object
  return response.data.channels;
}
