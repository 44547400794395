import { CartFragment } from '../../fragments';

/**
 * Portion of the GraphQL query used in the `updateCart` API endpoint
 */
export const updateCartMutation = `
  mutation updateCart(
    $id: String!,
    $version: Long!,
    $actions: [MyCartUpdateAction!]!,
    $locale: Locale!,
    $acceptLanguage: [Locale!],
    $currency: Currency!,
    $storeKey: KeyReferenceInput
  ) {
    cart: updateMyCart(id: $id, version: $version, actions: $actions, storeKey: $storeKey) {
      ...DefaultCart
    }
  }
`;

/**
 * Default GraphQL query for the `updateCart` API endpoint
 */
export const updateCartDefaultQuery = `
  ${CartFragment}

  ${updateCartMutation}
`;
