import { isAnonymousSession, isUserSession } from '../../helpers/utils';
import TokenParser from '../../token/tokenParser';
import type { Context } from '../../types/setup';
import { CT_COOKIE_NAME } from '../../types/setup';

/**
 * Endpoint for checking if user is a guest. It doesn't use any GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @returns Boolean indicating if user is a guest or not
 */
export async function isGuest(
  context: Context
): Promise<boolean> {
  const { config } = context;

  if (typeof config.handleIsGuest === 'function') {
    return config.handleIsGuest(context);
  }

  const token = TokenParser.parseToken(context.req?.cookies[CT_COOKIE_NAME] ?? '');
  return !isAnonymousSession(token) && !isUserSession(token);
}
