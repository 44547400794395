/**
 * Default GraphQL query for the `customerCreatePasswordResetToken` API endpoint
 */
export const customerCreatePasswordResetTokenDefaultQuery = `
  mutation customerCreatePasswordResetToken($email: String!, $storeKey: KeyReferenceInput) {
    customerCreatePasswordResetToken(email: $email, storeKey: $storeKey) {
      id
      #      customerId
      #      expiresAt
      #      value
      #      version
      #      createdAt
      #      lastModifiedAt
      #      createdBy {
      #        isPlatformClient
      #        externalUserId
      #        anonymousId
      #        clientId
      #        customerRef {
      #          typeId
      #          id
      #        }
      #        userRef {
      #          typeId
      #          id
      #        }
      #      }
      #      lastModifiedBy {
      #        isPlatformClient
      #        externalUserId
      #        anonymousId
      #        clientId
      #        customerRef {
      #          typeId
      #          id
      #        }
      #        userRef {
      #          typeId
      #          id
      #        }
      #      }
    }
  }
`;
