import { updateCart, UpdateCartResponse } from './../updateCart';
import { createRemoveLineItemAction } from '../../helpers/actions/cart';

import type { CustomQuery } from '@vue-storefront/core';
import type { LineItem } from '@vsf-enterprise/commercetools-types';
import type { Context } from '../../types/setup';

/**
 * First arameter for the `removeFromCart` API endpoint
 */
export interface RemoveFromCartParams {

  /**
   * Cart ID
   */
  id: string;

  /**
   * Cart version
   */
  version: string;
}

/**
 * Data returned from the `removeFromCart` API endpoint
 */
export type RemoveFromCartResponse = UpdateCartResponse;

/**
 * Endpoint for removing product from the cart. By default, it uses
 * the {@link updateCartDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param params - Cart information
 * @param product - Product information
 * @param customQuery - Custom queries included in the request
 * @returns User cart information
 */
export async function removeFromCart(
  context: Context,
  cart: RemoveFromCartParams,
  product: LineItem,
  customQuery?: CustomQuery
): Promise<RemoveFromCartResponse> {
  // TODO: We should consider merging the second and third parameters into one

  return await updateCart(
    context,
    {
      ...cart,
      actions: [createRemoveLineItemAction(product)]
    },
    customQuery
  );
}
