/* eslint-disable camelcase */
import type { IncomingMessage, ServerResponse } from 'http';
import type { default as SdkAuth, TokenProvider } from '@commercetools/sdk-auth';
import type { ApiClientExtension, CustomQuery } from '@vue-storefront/core';
import { CookieOptions } from 'express';
import type {
  ApolloClient,
  ApolloClientOptions,
  ApolloQueryResult,
  DocumentNode,
  FetchResult,
  GraphQLRequest,
  InMemoryCache,
  Operation
} from '@apollo/client/core';
import { InventoryMode } from '@vsf-enterprise/commercetools-types';

/**
 * Name of the cookie storing the access token
 */
export const CT_COOKIE_NAME = 'vsf-commercetools-token';

/**
 * @deprecated This interface is no longer used
 */
export interface ClientInstance extends ApolloClient<any> {
  sdkAuth?: SdkAuth;
  tokenProvider?: TokenProvider;
}

/**
 * The `api` property in integration configuration
 */
export interface ApiConfig {
  uri: string;
  authHost: string;
  projectKey: string;
  clientId: string;
  clientSecret: string;
  scopes: string[];
}

/**
 * The `serverApi` property in integration configuration
 */
export interface ServerApiConfiguration extends Pick<ApiConfig, 'clientId' | 'clientSecret' | 'scopes'> {
  operations?: string[];
}

/**
 * Token returned by commercetools API
 */
export interface Token {
  access_token: string;
  expires_at: number;
  expires_in: number;
  scope: string;
  token_type: string;
  refresh_token: string;
}

/**
 * Names of the cookies for user currency, country, locale, and store
 */
export interface CookiesConfig {
  currencyCookieName: string;
  countryCookieName: string;
  localeCookieName: string;
  storeCookieName: string;
  channelCookieName: string;
}

/**
 * @deprecated This interface is no longer used
 */
export interface LocaleItem {
  name: string;
  label: string;
}

/**
 * Object with helpers for reading and creating access token cookies and accessing current token provider
 */
export interface Auth {
  onTokenChange?: (token: Token) => void;
  onTokenRead?: () => Token;
  onTokenRemove?: () => void;
  setTokenProvider?: (token: Token) => void;
  getTokenProvider?: () => TokenProvider;
}

/**
 * @deprecated Use {@link Config} instead
 */
export interface SetupConfig<TCacheShape> {
  api?: ApiConfig;
  customOptions?: ApolloClientOptions<TCacheShape>;
  currency?: string;
  locale?: string;
  country?: string;
  countries?: LocaleItem[];
  currencies?: LocaleItem[];
  locales?: LocaleItem[];
  languageMap?: Record<string, any>;
  acceptLanguage?: string[];
  store: string;
  channel: string;
  cookies?: CookiesConfig;
  forceToken?: boolean;
}

type CookieOptionsList = {
  [key: string]: CookieOptions;
}

/**
 * Integration configuration after initialization and merging defaults
 */
export interface Config<TCacheShape = any> {

  /**
   * Credentials for requesting access tokens for guest, anonymous, and user sessions
   */
  api: ApiConfig;

  /**
   * Credentials for requesting access token with elevated permissions that users should not have
   */
  serverApi: ServerApiConfiguration;

  /**
   * Custom GraphQL client to be used instead of the default one. Use `customOptions` if you
   * only want to override configuration passed to the ApolloClient
   */
  client?: ApolloClient<TCacheShape>;

  /**
   * Configuration passed to the ApolloClient during initialization. Use `client` if you
   * want to use custom GraphQL client
   */
  customOptions?: ApolloClientOptions<TCacheShape>;

  /**
   * Default currency, used when request doesn't contain store cookie
   */
  currency?: string;

  /**
   * Default locale, used when request doesn't contain store cookie
   */
  locale?: string;

  /**
   * Default country, used when request doesn't contain store cookie
   */
  country?: string;

  /**
   * Default store, used when request doesn't contain store cookie
   */
  store: string;

  /**
   * Default channel, used when request doesn't contain channel cookie
   */
  channel: string;

  /**
   * An object used to map a locale to the accepted languages
   */
  languageMap?: Record<string, any>;

  /**
   * An array of supported locales
   */
  acceptLanguage?: string[];

  /**
   * @deprecated This property is no longer used
   */
  countries?: LocaleItem[];

  /**
   * @deprecated This property is no longer used
   */
  currencies?: LocaleItem[];

  /**
   * @deprecated This property is no longer used
   */
  locales?: LocaleItem[];

  /**
   * Names of the cookies for user currency, country, locale, and store
   */
  cookies?: CookiesConfig;

  /**
   * Customizable cookie options.
   */
  cookie_options?: CookieOptionsList,

  /**
   * Handler for retrying failed requests
   */
  customRetry?: (options: {
    count: number;
    operation: Operation;
    error: any;
  }) => boolean;

  /**
   * Handler for providing custom access token to the request sent to commercetools
   */
  customToken?: (options: {
    configuration: Config<TCacheShape>;
    isGuest: boolean;
    isServer: boolean;
    sdkAuth: SdkAuth;
    tokenProvider: TokenProvider;
    apolloReq: GraphQLRequest;
    currentToken: any;
  }) => any;

  /**
   * @deprecated This property is no longer used
   */
  handleIsTokenUserSession: (token: Token) => boolean;

  /**
   * Handler for checking if visitor is a guest, used in the `isGuest` API endpoint
   */
  handleIsGuest: (context: any) => boolean;

  /**
   * Handler for checking if visitor is logged in, used in the `isLoggedIn` API endpoint
   */
  handleIsLoggedIn: (context: any) => boolean;

  /**
   * Inventory mode used when creating a cart
   */
  inventoryMode?: InventoryMode;

  /**
   * @deprecated This property is no longer used
   */
  faceting: Record<string, any>;

  /**
   * @deprecated This property is no longer used
   */
  forceToken?: boolean;

  /**
   * SdkAuth instance
   */
  sdkAuth?: SdkAuth;
}

/**
 * Types of access tokens used by the integration
 */
export enum TokenType {
  ServerAccessToken = 'ServerAccessToken',
  GuestAccessToken = 'GuestAccessToken',
  AnonymousAccessToken = 'AnonymousAccessToken',
  ExistingAccessToken = 'ExistingAccessToken',
  UserAccessToken = 'UserAccessToken'
}

/**
 * An object containing a GraphQL query and its variables. It can be a custom query, default query,
 * or one of the values from the object returned by the {@link Context.extendQuery} helper
 */
interface ContextQuery {

  /**
   * GraphQL document that can be passed to Apollo client to make a request
   */
  query: string | DocumentNode;

  /**
   * Variables that can be passed to Apollo client to make a request
   */
  variables: Record<string, any>;
}

/**
 * Contains current request and response object, integration configuration and helper methods
 */
export interface Context {

  /**
   * Integration configuration
   */
  config: Config<InMemoryCache>;

  /**
   * Apollo GraphQL client used to send requests to commercetools platform
   */
  client: ApolloClient<InMemoryCache>;

  /**
   * Request object
   */
  req: IncomingMessage & { cookies?: { [key: string]: string } };

  /**
   * Response object
   */
  res: ServerResponse;

  /**
   * Array of registered integration extensions
   */
  extensions: ApiClientExtension[];

  /**
   * Custom queries registered in `middleware.config.js`
   */
  customQueries?: any;

  /**
   * Helper function for handling default and custom queries
   *
   * @param customQuery - custom query to be used instead of the default query
   * @param queries - default queries and variables
   * @returns Resolved queries
   */
  extendQuery<Type extends Record<string, ContextQuery>, Key extends keyof Type>(
    customQuery: CustomQuery,
    queries: Type
  ): Record<Key, ContextQuery>;
}

/**
 * Helper for mapping commercetools response to Apollo Client query result
 */
export type QueryResponse<K extends string, V> =
  ApolloQueryResult<Record<K, Partial<V>>>
  | FetchResult<Record<K, Partial<V>>>;
