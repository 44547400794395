import { Context, CustomQuery } from '@vue-storefront/core';
import { ErrorResponse, ForgotPasswordResult, ResetPasswordParams, SetNewPasswordParams } from '../types';
import { catchApiErrors } from '../helpers/internals/handleApiErrors';

type UseForgotPasswordMethods<RESULT> = {
  resetPassword: (context: Context, params: ResetPasswordParams & { currentResult: RESULT; customQuery?: CustomQuery, expose: boolean }) => Promise<RESULT | any>;
  setNewPassword: (context: Context, params: SetNewPasswordParams & { currentResult: RESULT; customQuery?: CustomQuery }) => Promise<RESULT | any>;
};

const useForgotPasswordMethods: UseForgotPasswordMethods<ForgotPasswordResult> = {
  resetPassword: async (context: Context, { email, currentResult, customQuery, expose }) => {
    const resetPasswordResult = await context.$ct.api.customerCreatePasswordResetToken(email, expose, customQuery);
    catchApiErrors({ ...resetPasswordResult, simple: true } as unknown as ErrorResponse);
    return {
      ...currentResult,
      resetPasswordResult
    };
  },
  setNewPassword: async (context: Context, { tokenValue, newPassword, currentResult, customQuery }) => {
    const setNewPasswordResult = await context.$ct.api.customerResetPassword(tokenValue, newPassword, customQuery);
    catchApiErrors(setNewPasswordResult as unknown as ErrorResponse);
    return {
      ...currentResult,
      setNewPasswordResult
    };
  }
};

export default useForgotPasswordMethods;
