import { InventoryEntryFragment } from './inventory';

/**
 * Portion of the {@link InventoryEntriesQueryResultFragment}
 */
export const DefaultInventoryEntriesQueryResultFragment = `
  fragment InventoryEntriesQueryResultFragment on InventoryEntryQueryResult {
    offset
    count
    total
    results {
      ...InventoryEntryFragment
    }
  }
`;

/**
 * Reusable GraphQL fragment. It concatenates
 * {@link InventoryEntryFragment} and {@link DefaultInventoryEntriesQueryResultFragment}
 */

export const InventoryEntriesQueryResultFragment = `
  ${InventoryEntryFragment}

  ${DefaultInventoryEntriesQueryResultFragment}
`;
