import gql from 'graphql-tag';
import { createMyOrderFromCartDefaultQuery } from './defaultQuery';
import { getStoreKey } from '../../helpers/utils';

import type { CustomQuery } from '@vue-storefront/core';
import type { Order } from '@vsf-enterprise/commercetools-types';
import type { Context, QueryResponse } from '../../types/setup';

/**
 * Parameters for the `createMyOrderFromCart` API endpoint
 */
export interface CreateMyOrderFromCartParams {

  /**
   * Cart ID
   */
  id: string;

  /**
   * Cart version
   */
  version: string | number;
}

/**
 * Data returned from the `createMyOrderFromCart` API endpoint
 */
export type CreateMyOrderFromCartResponse = QueryResponse<'order', Partial<Order>>;

/**
 * Endpoint for creating order from cart of the current user. By default, it uses
 * the {@link createMyOrderFromCartDefaultQuery} GraphQL query
 *
 * @param context - Automatically injected context. Refer to {@link Context}
 * @param draft - Cart details
 * @param customQuery - Custom queries included in the request
 * @returns Order information
 */
export async function createMyOrderFromCart(
  context: Context,
  draft: CreateMyOrderFromCartParams,
  customQuery?: CustomQuery
): Promise<CreateMyOrderFromCartResponse> {
  const { locale, acceptLanguage, currency, store } = context.config;

  const defaultVariables = {
    locale,
    acceptLanguage,
    currency,
    draft,
    ...getStoreKey(store)
  };

  const { createMyOrderFromCart } = context.extendQuery(
    customQuery,
    {
      createMyOrderFromCart: {
        query: createMyOrderFromCartDefaultQuery,
        variables: defaultVariables
      }
    }
  );

  return await context.client.mutate({
    mutation: gql`${createMyOrderFromCart.query}`,
    variables: createMyOrderFromCart.variables,
    fetchPolicy: 'no-cache',
    context: {
      req: context.req,
      res: context.res
    }
  });
}
